import './printPage.css'
import {LogoBlack} from "../../../../imgs";
import {forwardRef, useEffect} from "react";
import {isiOS} from "../../../../func";

const PrintPage = forwardRef((props, ref) => {
  let {team} = props;

  return (
    <div className={'fullPage'} ref={ref}
         style={{width: '210mm', height: isiOS() ? '286mm' : '297mm'}}>
      <div className={'pageHalf half1'} style={{height: isiOS() ? '60%' : '50%'}}>

      </div>
      <div className={'pageHalf half2'} style={{height: isiOS() ? '40%' : '50%'}}>
        <img className={'pageLogo'} src={LogoBlack} style={{height: isiOS() ? '50%' : '40%'}}/>
        <div>
          reserved for {team.name.toLowerCase()}
        </div>
        {team.totalSize !== undefined ? <div>
          {Array.isArray(team.totalSize) ? team.totalSize.join(', ') : team.totalSize} people - {team.time}
        </div> : <div>
          {Array.isArray(team.size) ? team.size.join(', ') : team.size} people - {team.time}
        </div>}
      </div>
    </div>
  );
});

export default PrintPage;