class Booking {
  constructor (name, email, size, date, time, bookingTime, expireAt) {
    this.name = name;
    this.email = email;
    this.size = size;
    this.date = date;
    this.time = time;
    this.bookingTime = bookingTime;
    this.expireAt = expireAt;
  }
}

const bookingConverter = {
  toFirestore: (booking) => {
    return {
      name: booking.name,
      email: booking.email,
      size: booking.size,
      date: booking.date,
      time: booking.time,
      bookingTime: booking.bookingTime,
      expireAt: booking.expireAt
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Booking(data.name, data.email, data.size, data.date, data.time, data.bookingTime, data.expireAt);
  }
}
export {Booking, bookingConverter}