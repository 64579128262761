import './quizBookingConfirm.css'
import './quizBookingConfirmMobile.css';
import {Loading, QuizConfirmImg} from "../../../imgs";

const QuizBookingConfirm = (props) => {
  let {translate, movePage, bookingInfo, sendBooking, width, loading, setLoading} = props


  return (
    <div style={{translate: translate}} className={'quizBookingSection quizBookingConfirmContainer'}>
      <h1>BOOKING DETAILS</h1>
      <div style={{display: loading ? 'flex' : 'none'}} className={'loadingConfirmContainer'}>
        <img src={Loading} />
      </div>
      <div className={'bookingDetailsInnerContainer'}>
        <div className={'bookingDetailsMessage'}>
          <div>
            Are the details below correct? If not, <b style={{color: '#da3838'}}>go back</b> to change them, if so, <b
            style={{color: '#06341c'}}>confirm</b> and book!
          </div>
        </div>
        {bookingInfo !== null ?
          <>
            <div className={'bookingDetailsContainer'}>
              <span><b>Name:</b> {bookingInfo.name}</span>
              <span><b>Team Size{bookingInfo.size.length > 1 ? `(s)` : ``}:</b> {bookingInfo.size.map((item) => item).join(', ')}</span>
              <span><b>Date:</b> {bookingInfo.date.split(' [SOLD OUT]')[0]}</span>
              <span><b>Time:</b> {bookingInfo.time}</span>
            </div>
          </> : <></>}
      </div>
      <img className={'quizBottomImg quizConfirmImg'} src={QuizConfirmImg} alt={'Illustration of some people enjoying the Mosaic Tap quiz.'}></img>
      <div className={'quizConfirmBottomContainer'}>
        <div className={'quizConfirmBtnContainer'}>
          <button style={{backgroundColor: '#da3838'}} className={'adminBtn quizBtn'} onClick={() => {
            movePage(1)
          }}>GO BACK
          </button>
          <button style={{backgroundColor: '#06341c'}} className={'adminBtn quizBtn'} onClick={() => {
            // TODO: Check whether quiz is FULL!
            sendBooking()
            // setPageNo(3)
          }}>CONFIRM
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuizBookingConfirm;