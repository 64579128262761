import './reviews.css';
import './reviewsMobile.css';
import './reviewsTablet.css';

import {useEffect, useRef, useState} from "react";
import ReactStars from "react-stars/dist/react-stars";
import useWindowDimensions from "../../func/getWindowDimensions";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {db} from "../../firebase";
import {doc, getDoc} from "firebase/firestore";

const BetterReviews = (props) => {
  const { height, width } = useWindowDimensions();
  let [reviews, setReviews] = useState([])

  const getReviews = async () => {
    // Try to get reviews from localStorage with expiration check
    const reviewDoc = await getDoc(doc(db, "reviews", "current"));
    setReviews(reviewDoc.data().reviews);
  }

  useEffect(() => {
    if (reviews.length < 5) {
      getReviews()
    }
  }, []);

  const settings = {
    arrows: width >= 640,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: width >= 640,
  };

  return (
    <div className={"reviewsContainer"}>
      <Slider {...settings}>
        {reviews.map((review, i) => (
          <div key={`slide${review.authorAttribution.displayName}`}>
            <div className={'reviewCard'} key={i}>
              <div className={'reviewHeader'}>
                <img onClick={() => {
                  window.open(review.authorAttribution.uri, "_blank")
                }} className={'reviewProfilePic'} src={review.authorAttribution.photoUri}/>
                <div className={'reviewProfileInfo'}>
                  <div className={'reviewText reviewProfileName'}>{review.authorAttribution.displayName}</div>
                  <div className={'reviewText reviewPublishTime'}>{review.relativePublishTimeDescription}</div>
                </div>
              </div>
              <div className={'reviewFooter'}>
                <ReactStars className={'reviewStars'} count={review.rating} value={review.rating} edit={false} size={24} color2={'#ffd700'}/>
                <div className={'reviewText text'}>{review.originalText.text}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default BetterReviews;