export const updateArrayAtIndex = (arr, setArr, index, newItem) => {
  const updatedArray = arr.map((item, i) => {
    if (i === index) {
      return newItem;
    } else {
      return item;
    }
  });

  setArr(updatedArray);
};