import {TableObj} from "../../objects";

export let tables = [
  new TableObj(1, 8, 0),
  new TableObj(2, 8, 0),
  new TableObj(3, 8, 0),
  new TableObj(4, 8, 0),
  new TableObj(5, 6, 0),
  new TableObj(6, 6, 0),
  new TableObj(7, 5, 0),
  new TableObj(8, 3, 0),
  new TableObj(9, 3, 0),
  new TableObj(10, 2, 0),
  new TableObj(11, 2, 0),
  new TableObj(12, 2, 0),
  new TableObj(13, 2, 0),
];