import './table.css';
import {useEffect, useState} from "react";

const Table = (props) => {
  const {table, x, y, width, height, tableSwap, setTableSwap} = props
  let [selected, setSelected] = useState(false);

  useEffect(() => {
    if (selected) {
      setSelected(false);
    }
  }, [table]);

  return (
    <foreignObject className={'tableInfoContainer'} x={x} y={y} width={width} height={height} style={{backgroundColor: selected ? '#3fd53f' : 'rgba(255,255,255,0)'}} >
      <div className={'tableInfo'}>
        <span>
          <b>ID:</b> {table.id} (<i>{table.currentSize}/{table.capacity}</i>)<span> </span>
          <button className={'swapTeamBtn'} onClick={() => {
            if (tableSwap.length < 1) {
              alert("Select a Team to move here first")
            } else if (tableSwap.length === 1) {
              setTableSwap([...tableSwap, table.id])
            }
          }}>➕</button>
        </span>
        <div>
          {table.teams.map((team, index) => (
            <div key={`${team.name}-${index}`}>
              {`${team.name} (${team.totalSize > 0 ? team.totalSize : team.size})`}<span> </span>
              <button className={'swapTeamBtn'} onClick={() => {
                if (JSON.stringify(tableSwap) === JSON.stringify([{table: table, team}])) {
                  setTableSwap([])
                  setSelected(false)
                } else if (tableSwap.length < 1) {
                  setTableSwap([{table: table, team}])
                  setSelected(true)
                } else if (tableSwap.length === 1) {
                  setTableSwap([...tableSwap, {table: table, team}])
                  setSelected(false)
                }
              }}>🔀</button>
            </div>
          ))}
        </div>
      </div>
    </foreignObject>
  )
}

export default Table;