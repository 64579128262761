// Stylesheets
import './updateBooking.css';
import './updateBookingMobile.css'

// React
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

//Firebase
import {collection, addDoc} from "firebase/firestore";
import {db} from "../../firebase";

// Objects and Components
import {QuizDialog} from "../../components";
import {deleteBooking, getBookingInformation} from "./firebaseFuncs";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {renderToString} from "react-dom/server";
import {EmailCancel} from "../../components/quizDialog/emailLayouts";
import {Loading} from "../../imgs";

const UpdateBooking = (props) => {
  let {quizOpen, setQuizOpen} = props;
  const { bookingId, email} = useParams();
  let navigate = useNavigate();

  const [checkingEmail, setCheckingEmail] = useState('');
  const [bookingInfo, setBookingInfo] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [shouldSendEmail, setShouldSendEmail] = useState(true);
  const [valid, setValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Auth
  let {user, setUser} = props;
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    }
  });

  // Stop "Are you sure?" showing after 2.5s
  useEffect(() => {
    if (isCancelling) {
      setTimeout(() => {
        setIsCancelling(false);
      }, 2500)
    }
  }, [isCancelling]);

  useEffect(() => {
    quizOpen ? setIsUpdating(true) : setIsUpdating(false);
  }, [quizOpen])

  // Get booking information on load, or if has stopped updating, get new information
  useEffect(() => {
    if (!isUpdating && bookingId !== undefined) {
      setValid(false)
      getBookingInformation(bookingId).then(bookingInfo => {
        if (bookingInfo !== undefined) {
          setValid(true)
          if (checkingEmail !== '' || email !== undefined) {
            setBookingInfo(bookingInfo);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
    }

  }, [isUpdating]);

  return (
    <div className={'bookingContainer'}>
      {(email !== undefined && bookingInfo !== null && email === bookingInfo.email) ? <>
          <QuizDialog open={quizOpen} setOpen={setQuizOpen} update={true} existingBookingInfo={bookingInfo}
                      bookingId={bookingId}/>
          <h1 className={'bookingHeader'}>QUIZ BOOKING</h1>
          {bookingInfo !== null ?
            <>
              <div className={'bookingInfoContainer'}>
                <>
                  <span><b>Name:</b> {bookingInfo.name} ({bookingInfo.email})</span>
                  <span><b>Team Size{bookingInfo.size.length > 1 ? `(s)` : ``}:</b> {bookingInfo.size.map((item) => item).join(', ')}</span>
                  <span><b>Date:</b> {bookingInfo.date}</span>
                  <span><b>Time:</b> {bookingInfo.time}</span>
                </>

                <div className={'bookingBtnContainer'}>
                <button className={'adminBtn bookingBtn'} onClick={() => {
                    setQuizOpen(true)
                  }}>UPDATE
                  </button>
                  <button
                    style={{backgroundColor: isCancelling ? `#e81d1d` : ``}}
                    className={'adminBtn bookingBtn'}
                    onClick={() => {
                      if (isCancelling) {
                        deleteBooking(bookingId).then(async () => {
                          if (!shouldSendEmail && user) {
                            navigate('/admin/tables')
                          } else if (shouldSendEmail) {
                            const mailRef = collection(db, "mail");

                            let message = {
                              to: bookingInfo.email,
                              bcc: "isaac@mosaictap.com",
                              message: {
                                subject: `Mosaic Tap Quiz Booking Cancelled for ${bookingInfo.name} on ${bookingInfo.date.split(' [SOLD OUT]')[0]}`,
                                html: renderToString(<EmailCancel bookingInfo={bookingInfo}/>)
                              }
                            }

                            await addDoc(mailRef, message).then(() => {
                              alert("You have successfully cancelled your booking.");
                              navigate('/')
                            })
                          }
                        })
                      } else {
                        setIsCancelling(true)
                      }
                    }}>{isCancelling ? `ARE YOU SURE?` : `CANCEL`}
                  </button>
                </div>
              </div>
            </>
            :
            <div>
              No booking information found, please contact <a href={"mailto:info@mosaictap.com"}>info@mosaictap.com</a> if
              this is a mistake.
            </div>}
          {user ? <div>
            <label>
              <input
                type="checkbox"
                id="cancelEmailCheck"
                name="cancelEmailCheck"
                checked={shouldSendEmail}
                onChange={(e) => {
                  setShouldSendEmail(e.target.checked)
                }}
              />
              Should send cancellation email?
            </label>
          </div> : <></>}
        </> :
        (!valid ?
          !isLoading ?
            <>
              <div className={'identityContainer'}>
                <div>
                  This booking no longer exists. If you think this is incorrect, please contact: <a href={"mailto:info@mosaictap.com"}>info@mosaictap.com</a>.
                </div>
              </div>
            </>
            :
            <>
              <div className={'identityContainer'}>
                <img src={Loading}/>
              </div>
            </>
          :
          <div className={'identityContainer'}>
            <div>
              To access your booking, please enter the email address associated with the booking:
            </div>
            <input id={'email'} onChange={(event) => {
              setCheckingEmail(event.target.value);
            }} placeholder={"Email (For booking confirmation)"} value={checkingEmail} type={'email'}></input>
            <button className={'adminBtn quizBtn'} onClick={() => {
              if (checkingEmail === bookingInfo.email) {
                navigate(`/booking/${bookingId}/${checkingEmail}`)
              } else {
                alert("Incorrect email")
              }
            }}>SUBMIT
            </button>
        </div>)}
    </div>
  )
}

export default UpdateBooking;