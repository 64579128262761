import './quizBookingSuccess.css';
import './quizBookingSuccessMobile.css';
import {QuizConfirmImg, QuizSuccess} from "../../../imgs";

const QuizBookingSuccess = (props) => {
  let {translate, setOpen, bookingInfo} = props

  return (
    <div style={{translate: translate}} className={'quizBookingSection quizBookingConfirmContainer'}>
      <h1>WOOHOO!</h1>
      <div className={'bookingDetailsInnerContainer'}>
        {bookingInfo !== null ? <>
          <div className={'bookingDetailsMessage'}>
            Thanks for booking the Mosaic Tap Quiz, a booking confirmation will be sent
            to <b>{bookingInfo.email}</b> shortly. Please allow up to 15 minutes for it to arrive, also remember to
            check your spam folder. <br/><br/> See you
            on <b>{bookingInfo.date.split(' [SOLD OUT]')[0].replace('Wed', 'Wednesday')}</b> @ <b>{bookingInfo.time}</b>!
          </div>
        </> : <></>}
      </div>
      <img className={'quizBottomImg quizSuccessImg'} src={QuizSuccess} alt={'Illustration of some drinks.'}></img>
      <div className={'quizConfirmBottomContainer'}>
        <div className={'quizConfirmBtnContainer'}>
          <button className={'adminBtn quizBtn'} onClick={() => {
            setOpen(false);
          }}>DISMISS
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuizBookingSuccess