import './heroImages.css';
import './heroImagesMobile.css';
import './heroImagesTablet.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {useEffect, useRef, useState} from "react";
import {listAll, getStorage, ref, getDownloadURL} from "firebase/storage";
import useWindowDimensions from "../../func/getWindowDimensions";
import Slider from "react-slick";

const HeroImages = (props) => {
    const { height, width } = useWindowDimensions();

    const storage = getStorage();
    const imagesRef = ref(storage, 'Hero');

    const [images, setImages] = useState([])
    const [gotImages, setGotImages] = useState(false)

    const getCurrentImages = async () => {
        listAll(imagesRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    getDownloadURL(itemRef).then((url) => {
                        setImages(images => [...images, url]);
                    })

                });
            }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    useEffect(() => {
        if (images.length >= 0) {
            setGotImages(true)
        }
    }, []);

    useEffect(() => {
        if (!gotImages) {
            setGotImages(true)
            getCurrentImages()
        }
    }, [gotImages]);

  const heroSettings = {
    dots: true,
    arrows: width >= 640,
    speed: 500,
    slidesToShow: width < 640 ? 1 : 3,
    slidesToScroll: width < 640 ? 1 : 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: true,
    pauseOnHover: true,
    centerMode: width >= 640,
    variableWidth: width >= 640,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

    return (
      <div className={"heroImagesContainer"}>
        <Slider {...heroSettings}>
            {images.map((url, index) => (
              <div key={index}>
                  <img className="slide" src={url} alt={`Slide ${index}`} />
              </div>
            ))}
        </Slider>
      </div>
    )
}

export default HeroImages;