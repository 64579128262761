import './privacy.css'

const Privacy = () => {
    return (
        <div className={'privacyContainer'}>
            <>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
                    }}
                />
                <div data-custom-class="body">
                    <div>
                        <strong>
        <span style={{ fontSize: 26 }}>
          <span data-custom-class="title">
            <bdt className="block-component" />
            <bdt className="question">PRIVACY POLICY</bdt>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
                        </strong>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">June 12, 2024</bdt>
            </span>
          </span>
        </strong>
      </span>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This privacy notice for{" "}
              <bdt className="question">
              Mosaic Tap
              <bdt className="block-component" />
            </bdt>{" "}
              (<bdt className="block-component" />'<strong>we</strong>', '
            <strong>us</strong>', or '<strong>our</strong>'
            <bdt className="else-block" />
          </span>
          <span data-custom-class="body_text">
            ), describes how and why we might collect, store, use, and/or share
            (<bdt className="block-component" />'<strong>process</strong>'
            <bdt className="else-block" />) your information when you use our
            services (<bdt className="block-component" />'
            <strong>Services</strong>'<bdt className="else-block" />
            ), such as when you:
          </span>
        </span>
      </span>
                        <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Visit our website
              <bdt className="block-component" /> at{" "}
                <span style={{ color: "rgb(0, 58, 250)" }}>
                <bdt className="question">
                  <a
                      href="https://mosaictap.com"
                      target="_blank"
                      data-custom-class="link"
                  >
                    https://mosaictap.com
                  </a>
                </bdt>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          , or any website of ours that links to this privacy
                          notice
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </li>
                    </ul>
                    <div>
                        <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services.
              <bdt className="block-component" /> If you still have any
              questions or concerns, please contact us at{" "}
                <bdt className="question">info@mosaictap.com</bdt>.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
                            </strong>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our&nbsp;
              </em>
            </strong>
          </span>
        </span>
                            <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#personalinfo">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              personal information you disclose to us
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>{" "}
              <bdt className="block-component" />
            We do not process sensitive personal information.
            <bdt className="else-block" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we collect any information from third parties?</strong>{" "}
              <bdt className="block-component" />
            We do not collect any information from third parties.
            <bdt className="else-block" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we process your information
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
                <bdt className="block-component" />
              types of <bdt className="statement-end-if-in-editor" />
              parties do we share personal information?
            </strong>{" "}
              We may share information in specific situations and with specific{" "}
              <bdt className="block-component" />
            categories of <bdt className="statement-end-if-in-editor" />
            third parties. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#whoshare">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              when and with whom we share your personal information
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            .<bdt className="block-component" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have{" "}
              <bdt className="block-component" />
            organisational
            <bdt className="else-block" /> and technical processes and
            procedures in place to protect your personal information. However,
            no electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other{" "}
              <bdt className="block-component" />
            unauthorised
            <bdt className="else-block" /> third parties will not be able to
            defeat our security and improperly collect, access, steal, or modify
            your information. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we keep your information safe
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by{" "}
              <bdt className="block-component">submitting a&nbsp;</bdt>
          </span>
        </span>
                            <a
                                data-custom-class="link"
                                href="https://app.termly.io/notify/22e6bebd-8f21-4136-afd2-9b7e06fa5356"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              data subject access request
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />, or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Review the privacy notice in full
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">.</span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
              <bdt className="block-component" />
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#legalbases">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              3.{" "}
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </span>
              </span>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#whoshare">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infominors">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#privacyrights">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
              <bdt className="block-component" />
            </span>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </bdt>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </bdt>
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <span style={{ fontSize: 15 }} />
                            </a>
                            <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            11. DO WE MAKE UPDATES TO THIS NOTICE?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        We collect personal information that you provide to us.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
                <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
            <span data-custom-class="body_text">
              express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the
              Services, or otherwise when you contact us.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>{" "}
              <bdt className="block-component" />
            We do not process sensitive information.
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="else-block" />
          </span>
        </span>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component" />
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Information automatically collected</strong>
          </span>
        </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is
                        collected automatically when you visit our Services.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The information we collect includes:
              <bdt className="block-component" />
            </span>
          </span>
        </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
                                </bdt>
                            </div>
                            <ul>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
                                </bdt>
                            </div>
                            <ul>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </li>
                            </ul>
                            <div>
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </bdt>
                                <bdt className="statement-end-if-in-editor" />
                                <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
                                <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <br />
                            </div>
                            <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                            <div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </div>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
                                <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
                                    <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
                                        <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                                            <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }} />
                                                            </bdt>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                                                                </bdt>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </bdt>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </bdt>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component" />
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component" />
                                                                </p>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                                                                    </bdt>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }} />
                                                                        </bdt>
                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                            <bdt className="block-component">
                                                                                <span style={{ fontSize: 15 }} />
                                                                            </bdt>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                  </span>
                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                                                                    </bdt>
                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                        <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                                                                        </bdt>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                                                                            </bdt>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                                                                                </bdt>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                                                                                    </bdt>
                                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                                        <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                                                                                        </bdt>
                                                                                                        <div
                                                                                                            style={{ lineHeight: "1.5" }}
                                                                                                        >
                                                                                                            <bdt className="block-component">
                                                  <span
                                                      style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                                                                            </bdt>
                                                                                                            <div
                                                                                                                style={{ lineHeight: "1.5" }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                    <span
                                                        style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                                                                                </bdt>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                      <span
                                                          style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                                                                                    </bdt>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component">
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                                                                                        </bdt>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li
                                                                                                                                data-custom-class="body_text"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <strong>
                                                                To save or
                                                                protect an
                                                                individual's
                                                                vital interest.
                                                              </strong>{" "}
                                                                We may process
                                                              your information
                                                              when necessary to
                                                              save or protect an
                                                              individual’s vital
                                                              interest, such as
                                                              to prevent harm.
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            id="legalbases"
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <strong>
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="heading_1">
                                                              3. WHAT LEGAL
                                                              BASES DO WE RELY
                                                              ON TO PROCESS YOUR
                                                              INFORMATION?
                                                            </span>
                                                          </span>
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <em>
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                In Short:&nbsp;
                                                              </strong>
                                                              We only process
                                                              your personal
                                                              information when
                                                              we believe it is
                                                              necessary and we
                                                              have a valid legal
                                                              reason (i.e.
                                                              <bdt className="block-component" />{" "}
                                                                legal basis) to do
                                                              so under
                                                              applicable law,
                                                              like with your
                                                              consent, to comply
                                                              with laws, to
                                                              provide you with
                                                              services to enter
                                                              into or{" "}
                                                                <bdt className="block-component" />
                                                              fulfil
                                                              <bdt className="else-block" />{" "}
                                                                our contractual
                                                              obligations, to
                                                              protect your
                                                              rights, or to{" "}
                                                                <bdt className="block-component" />
                                                              fulfil
                                                              <bdt className="else-block" />{" "}
                                                                our legitimate
                                                              business
                                                              interests.
                                                            </span>
                                                          </span>
                                                                                                                            </em>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            The General Data
                                                            Protection
                                                            Regulation (GDPR)
                                                            and UK GDPR require
                                                            us to explain the
                                                            valid legal bases we
                                                            rely on in order to
                                                            process your
                                                            personal
                                                            information. As
                                                            such, we may rely on
                                                            the following legal
                                                            bases to process
                                                            your personal
                                                            information:
                                                          </span>
                                                        </span>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li
                                                                                                                                data-custom-class="body_text"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Consent.&nbsp;
                                                              </strong>
                                                              We may process
                                                              your information
                                                              if you have given
                                                              us permission
                                                              (i.e.
                                                              <bdt className="block-component" />{" "}
                                                                consent) to use
                                                              your personal
                                                              information for a
                                                              specific purpose.
                                                              You can withdraw
                                                              your consent at
                                                              any time. Learn
                                                              more about&nbsp;
                                                            </span>
                                                          </span>
                                                                                                                                <a
                                                                                                                                    data-custom-class="link"
                                                                                                                                    href="#withdrawconsent"
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "rgb(0, 58, 250)",
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                withdrawing your
                                                                consent
                                                              </span>
                                                            </span>
                                                                                                                                </a>
                                                                                                                                <span data-custom-class="body_text">
                                                            .
                                                          </span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <strong>
                                                                  Legal
                                                                  Obligations.
                                                                </strong>{" "}
                                                                  We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                for compliance
                                                                with our legal
                                                                obligations,
                                                                such as to
                                                                cooperate with a
                                                                law enforcement
                                                                body or
                                                                regulatory
                                                                agency, exercise
                                                                or defend our
                                                                legal rights, or
                                                                disclose your
                                                                information as
                                                                evidence in
                                                                litigation in
                                                                which we are
                                                                involved.
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <br />
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <strong>
                                                                  Vital
                                                                  Interests.
                                                                </strong>{" "}
                                                                  We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                to protect your
                                                                vital interests
                                                                or the vital
                                                                interests of a
                                                                third party,
                                                                such as
                                                                situations
                                                                involving
                                                                potential
                                                                threats to the
                                                                safety of any
                                                                person.
                                                              </span>
                                                            </span>
                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                                                                                                    </bdt>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                                <span data-custom-class="body_text">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <bdt className="block-component">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                id="whoshare"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  color:
                                                                      "rgb(127, 127, 127)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                  <span
                                                                      id="control"
                                                                      style={{
                                                                          color:
                                                                              "rgb(0, 0, 0)"
                                                                      }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        4. WHEN
                                                                        AND WITH
                                                                        WHOM DO
                                                                        WE SHARE
                                                                        YOUR
                                                                        PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>
                                                                    In Short:
                                                                  </em>
                                                                </strong>
                                                                <em>
                                                                  &nbsp;We may
                                                                  share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in
                                                                  this section
                                                                  and/or with
                                                                  the following{" "}
                                                                    <bdt className="block-component" />
                                                                  categories of{" "}
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Vendors,
                                                                Consultants, and
                                                                Other
                                                                Third-Party
                                                                Service
                                                                Providers.
                                                              </strong>{" "}
                                                                We may share your
                                                              data with
                                                              third-party
                                                              vendors, service
                                                              providers,
                                                              contractors, or
                                                              agents (
                                                              <bdt className="block-component" />
                                                              '
                                                              <strong>
                                                                third parties
                                                              </strong>
                                                              '
                                                              <bdt className="else-block" />
                                                              ) who perform
                                                              services for us or
                                                              on our behalf and
                                                              require access to
                                                              such information
                                                              to do that work.{" "}
                                                                <bdt className="block-component" />
                                                              We have contracts
                                                              in place with our
                                                              third parties,
                                                              which are designed
                                                              to help safeguard
                                                              your personal
                                                              information. This
                                                              means that they
                                                              cannot do anything
                                                              with your personal
                                                              information unless
                                                              we have instructed
                                                              them to do it.
                                                              They will also not
                                                              share your
                                                              personal
                                                              information with
                                                              any{" "}
                                                                <bdt className="block-component" />
                                                              organisation
                                                              <bdt className="else-block" />{" "}
                                                                apart from us.
                                                              They also commit
                                                              to pr
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              otect the data
                                                              they hold on our
                                                              behalf and to
                                                              retain it for the
                                                              period we
                                                              instruct.
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <bdt className="block-component" />
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              The{" "}
                                                                <bdt className="block-component" />
                                                              categories of{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                              third parties we
                                                              may share personal
                                                              information with
                                                              are as follows:
                                                            </span>
                                                          </span>
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Data
                                                                    Analytics
                                                                    Services
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div>
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span
                                                                      style={{
                                                                          color:
                                                                              "rgb(89, 89, 89)"
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Performance
                                                                    Monitoring
                                                                    Tools
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div>
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span
                                                                      style={{
                                                                          color:
                                                                              "rgb(89, 89, 89)"
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                                "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                              style={{
                                                                                                  fontSize: 15
                                                                                              }}
                                                                                          >
                                                                                            <span
                                                                                                style={{
                                                                                                    color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15
                                                                                                    }}
                                                                                                >
                                                                                                  <span
                                                                                                      style={{
                                                                                                          color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                  >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15
                                                                                                        }}
                                                                                                    >
                                                                                                      <span
                                                                                                          style={{
                                                                                                              color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                      >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                          <span
                                                                                                              style={{
                                                                                                                  color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                          >
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We{" "}
                                                                <bdt className="block-component" />
                                                              also{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                              may need to share
                                                              your personal
                                                              information in the
                                                              following
                                                              situations:
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Business
                                                                  Transfers.
                                                                </strong>{" "}
                                                                  We may share or
                                                                transfer your
                                                                information in
                                                                connection with,
                                                                or during
                                                                negotiations of,
                                                                any merger, sale
                                                                of company
                                                                assets,
                                                                financing, or
                                                                acquisition of
                                                                all or a portion
                                                                of our business
                                                                to another
                                                                company.
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                                                                                                        </bdt>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "1.5"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <bdt className="block-component">
                                                                  <span
                                                                      style={{
                                                                          fontSize: 15
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                                                                                            </bdt>
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    lineHeight:
                                                                                                                                                        "1.5"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <bdt className="block-component">
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                                                                                                </bdt>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <bdt className="block-component">
                                                                                <span data-custom-class="heading_1">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="cookies"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  5.
                                                                                  DO
                                                                                  WE
                                                                                  USE
                                                                                  COOKIES
                                                                                  AND
                                                                                  OTHER
                                                                                  TRACKING
                                                                                  TECHNOLOGIES?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            may
                                                                            use
                                                                            cookies
                                                                            and
                                                                            other
                                                                            tracking
                                                                            technologies
                                                                            to
                                                                            collect
                                                                            and
                                                                            store
                                                                            your
                                                                            information.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          similar
                                                                          tracking
                                                                          technologies
                                                                          (like
                                                                          web
                                                                          beacons
                                                                          and
                                                                          pixels)
                                                                          to
                                                                          gather
                                                                          information
                                                                          when
                                                                          you
                                                                          interact
                                                                          with
                                                                          our
                                                                          Services.
                                                                          Some
                                                                          online
                                                                          tracking
                                                                          technologies
                                                                          help
                                                                          us
                                                                          maintain
                                                                          the
                                                                          security
                                                                          of our
                                                                          Services
                                                                          <bdt className="block-component" />
                                                                          ,
                                                                          prevent
                                                                          crashes,
                                                                          fix
                                                                          bugs,
                                                                          save
                                                                          your
                                                                          preferences,
                                                                          and
                                                                          assist
                                                                          with
                                                                          basic
                                                                          site
                                                                          functions.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          also
                                                                          permit
                                                                          third
                                                                          parties
                                                                          and
                                                                          service
                                                                          providers
                                                                          to use
                                                                          online
                                                                          tracking
                                                                          technologies
                                                                          on our
                                                                          Services
                                                                          for
                                                                          analytics
                                                                          and
                                                                          advertising,
                                                                          including
                                                                          to
                                                                          help
                                                                          manage
                                                                          and
                                                                          display
                                                                          advertisements,
                                                                          to
                                                                          tailor
                                                                          advertisements
                                                                          to
                                                                          your
                                                                          interests,
                                                                          or to
                                                                          send
                                                                          abandoned
                                                                          shopping
                                                                          cart
                                                                          reminders
                                                                          (depending
                                                                          on
                                                                          your
                                                                          communication
                                                                          preferences).
                                                                          The
                                                                          third
                                                                          parties
                                                                          and
                                                                          service
                                                                          providers
                                                                          use
                                                                          their
                                                                          technology
                                                                          to
                                                                          provide
                                                                          advertising
                                                                          about
                                                                          products
                                                                          and
                                                                          services
                                                                          tailored
                                                                          to
                                                                          your
                                                                          interests
                                                                          which
                                                                          may
                                                                          appear
                                                                          either
                                                                          on our
                                                                          Services
                                                                          or on
                                                                          other
                                                                          websites.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Specific
                                                                          information
                                                                          about
                                                                          how we
                                                                          use
                                                                          such
                                                                          technologies
                                                                          and
                                                                          how
                                                                          you
                                                                          can
                                                                          refuse
                                                                          certain
                                                                          cookies
                                                                          is set
                                                                          out in
                                                                          our
                                                                          Cookie
                                                                          Notice
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              .
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <br />
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_2">
                                                                          Google
                                                                          Analytics
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <br />
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We may
                                                                        share
                                                                        your
                                                                        information
                                                                        with
                                                                        Google
                                                                        Analytics
                                                                        to track
                                                                        and{" "}
                                                                          <bdt className="block-component" />
                                                                        analyse
                                                                        <bdt className="else-block" />{" "}
                                                                          the use
                                                                        of the
                                                                        Services.
                                                                        <bdt className="block-component" />{" "}
                                                                          The
                                                                        Google
                                                                        Analytics
                                                                        Advertising
                                                                        Features
                                                                        that we
                                                                        may use
                                                                        include:{" "}
                                                                          <bdt className="forloop-component" />
                                                                        <bdt className="block-component" />
                                                                        <bdt className="question">
                                                                          Google
                                                                          Analytics
                                                                          Demographics
                                                                          and
                                                                          Interests
                                                                          Reporting
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="forloop-component" />
                                                                        .
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                          To opt
                                                                        out of
                                                                        being
                                                                        tracked
                                                                        by
                                                                        Google
                                                                        Analytics
                                                                        across
                                                                        the
                                                                        Services,
                                                                        visit{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-fr-linked="true"
                                                                              href="https://tools.google.com/dlpage/gaoptout"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            https://tools.google.com/dlpage/gaoptout
                                                                          </a>
                                                                          .
                                                                        </span>
                                                                        <bdt className="block-component" />{" "}
                                                                          You can
                                                                        opt out
                                                                        of
                                                                        Google
                                                                        Analytics
                                                                        Advertising
                                                                        Features
                                                                        through{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="https://adssettings.google.com/"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            Ads
                                                                            Settings
                                                                          </a>
                                                                        </span>{" "}
                                                                          and Ad
                                                                        Settings
                                                                        for
                                                                        mobile
                                                                        apps.
                                                                        Other
                                                                        opt out
                                                                        means
                                                                        include{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rbg(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                            <a
                                                                                data-custom-class="link"
                                                                                href="http://optout.networkadvertising.org/"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                            >
                                                                              http://optout.networkadvertising.org/
                                                                            </a>
                                                                          </span>
                                                                        </span>{" "}
                                                                          and{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="http://www.networkadvertising.org/mobile-choice"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            http://www.networkadvertising.org/mobile-choice
                                                                          </a>
                                                                        </span>
                                                                        .
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                          For more
                                                                        information
                                                                        on the
                                                                        privacy
                                                                        practices
                                                                        of
                                                                        Google,
                                                                        please
                                                                        visit
                                                                        the{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="https://policies.google.com/privacy"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            Google
                                                                            Privacy
                                                                              &amp;
                                                                              Terms
                                                                            page
                                                                          </a>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize: 15
                                                                                      }}
                                                                                  >
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                  <span
                                                                                      style={{
                                                                                          color:
                                                                                              "rgb(89, 89, 89)"
                                                                                      }}
                                                                                  >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                        <bdt className="block-component">
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="inforetain"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  6.
                                                                                  HOW
                                                                                  LONG
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            keep
                                                                            your
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            necessary
                                                                            to{" "}
                                                                              <bdt className="block-component" />
                                                                            fulfil
                                                                            <bdt className="else-block" />{" "}
                                                                              the
                                                                            purposes
                                                                            outlined
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            unless
                                                                            otherwise
                                                                            required
                                                                            by
                                                                            law.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          only
                                                                          keep
                                                                          your
                                                                          personal
                                                                          information
                                                                          for as
                                                                          long
                                                                          as it
                                                                          is
                                                                          necessary
                                                                          for
                                                                          the
                                                                          purposes
                                                                          set
                                                                          out in
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          unless
                                                                          a
                                                                          longer
                                                                          retention
                                                                          period
                                                                          is
                                                                          required
                                                                          or
                                                                          permitted
                                                                          by law
                                                                          (such
                                                                          as
                                                                          tax,
                                                                          accounting,
                                                                          or
                                                                          other
                                                                          legal
                                                                          requirements).
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          When
                                                                          we
                                                                          have
                                                                          no
                                                                          ongoing
                                                                          legitimate
                                                                          business
                                                                          need
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          we
                                                                          will
                                                                          either
                                                                          delete
                                                                          or{" "}
                                                                            <bdt className="block-component" />
                                                                          anonymise
                                                                          <bdt className="else-block" />{" "}
                                                                            such
                                                                          information,
                                                                          or, if
                                                                          this
                                                                          is not
                                                                          possible
                                                                          (for
                                                                          example,
                                                                          because
                                                                          your
                                                                          personal
                                                                          information
                                                                          has
                                                                          been
                                                                          stored
                                                                          in
                                                                          backup
                                                                          archives),
                                                                          then
                                                                          we
                                                                          will
                                                                          securely
                                                                          store
                                                                          your
                                                                          personal
                                                                          information
                                                                          and
                                                                          isolate
                                                                          it
                                                                          from
                                                                          any
                                                                          further
                                                                          processing
                                                                          until
                                                                          deletion
                                                                          is
                                                                          possible.
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="infosafe"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  7.
                                                                                  HOW
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION
                                                                                  SAFE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            aim
                                                                            to
                                                                            protect
                                                                            your
                                                                            personal
                                                                            information
                                                                            through
                                                                            a
                                                                            system
                                                                            of{" "}
                                                                              <bdt className="block-component" />
                                                                            organisational
                                                                            <bdt className="else-block" />{" "}
                                                                              and
                                                                            technical
                                                                            security
                                                                            measures.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          have
                                                                          implemented
                                                                          appropriate
                                                                          and
                                                                          reasonable
                                                                          technical
                                                                          and{" "}
                                                                            <bdt className="block-component" />
                                                                          organisational
                                                                          <bdt className="else-block" />{" "}
                                                                            security
                                                                          measures
                                                                          designed
                                                                          to
                                                                          protect
                                                                          the
                                                                          security
                                                                          of any
                                                                          personal
                                                                          information
                                                                          we
                                                                          process.
                                                                          However,
                                                                          despite
                                                                          our
                                                                          safeguards
                                                                          and
                                                                          efforts
                                                                          to
                                                                          secure
                                                                          your
                                                                          information,
                                                                          no
                                                                          electronic
                                                                          transmission
                                                                          over
                                                                          the
                                                                          Internet
                                                                          or
                                                                          information
                                                                          storage
                                                                          technology
                                                                          can be
                                                                          guaranteed
                                                                          to be
                                                                          100%
                                                                          secure,
                                                                          so we
                                                                          cannot
                                                                          promise
                                                                          or
                                                                          guarantee
                                                                          that
                                                                          hackers,
                                                                          cybercriminals,
                                                                          or
                                                                          other{" "}
                                                                            <bdt className="block-component" />
                                                                          unauthorised
                                                                          <bdt className="else-block" />{" "}
                                                                            third
                                                                          parties
                                                                          will
                                                                          not be
                                                                          able
                                                                          to
                                                                          defeat
                                                                          our
                                                                          security
                                                                          and
                                                                          improperly
                                                                          collect,
                                                                          access,
                                                                          steal,
                                                                          or
                                                                          modify
                                                                          your
                                                                          information.
                                                                          Although
                                                                          we
                                                                          will
                                                                          do our
                                                                          best
                                                                          to
                                                                          protect
                                                                          your
                                                                          personal
                                                                          information,
                                                                          transmission
                                                                          of
                                                                          personal
                                                                          information
                                                                          to and
                                                                          from
                                                                          our
                                                                          Services
                                                                          is at
                                                                          your
                                                                          own
                                                                          risk.
                                                                          You
                                                                          should
                                                                          only
                                                                          access
                                                                          the
                                                                          Services
                                                                          within
                                                                          a
                                                                          secure
                                                                          environment.
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="infominors"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  8.
                                                                                  DO
                                                                                  WE
                                                                                  COLLECT
                                                                                  INFORMATION
                                                                                  FROM
                                                                                  MINORS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            do
                                                                            not
                                                                            knowingly
                                                                            collect
                                                                            data
                                                                            from
                                                                            or
                                                                            market
                                                                            to{" "}
                                                                              <bdt className="block-component" />
                                                                            children
                                                                            under
                                                                            18
                                                                            years
                                                                            of
                                                                            age
                                                                            <bdt className="else-block" />
                                                                            .
                                                                          </em>
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We do
                                                                          not
                                                                          knowingly
                                                                          collect,
                                                                          solicit
                                                                          data
                                                                          from,
                                                                          or
                                                                          market
                                                                          to
                                                                          children
                                                                          under
                                                                          18
                                                                          years
                                                                          of
                                                                          age,
                                                                          nor do
                                                                          we
                                                                          knowingly
                                                                          sell
                                                                          such
                                                                          personal
                                                                          information.
                                                                          By
                                                                          using
                                                                          the
                                                                          Services,
                                                                          you
                                                                          represent
                                                                          that
                                                                          you
                                                                          are at
                                                                          least
                                                                          18 or
                                                                          that
                                                                          you
                                                                          are
                                                                          the
                                                                          parent
                                                                          or
                                                                          guardian
                                                                          of
                                                                          such a
                                                                          minor
                                                                          and
                                                                          consent
                                                                          to
                                                                          such
                                                                          minor
                                                                          dependent’s
                                                                          use of
                                                                          the
                                                                          Services.
                                                                          If we
                                                                          learn
                                                                          that
                                                                          personal
                                                                          information
                                                                          from
                                                                          users
                                                                          less
                                                                          than
                                                                          18
                                                                          years
                                                                          of age
                                                                          has
                                                                          been
                                                                          collected,
                                                                          we
                                                                          will
                                                                          deactivate
                                                                          the
                                                                          account
                                                                          and
                                                                          take
                                                                          reasonable
                                                                          measures
                                                                          to
                                                                          promptly
                                                                          delete
                                                                          such
                                                                          data
                                                                          from
                                                                          our
                                                                          records.
                                                                          If you
                                                                          become
                                                                          aware
                                                                          of any
                                                                          data
                                                                          we may
                                                                          have
                                                                          collected
                                                                          from
                                                                          children
                                                                          under
                                                                          age
                                                                          18,
                                                                          please
                                                                          contact
                                                                          us at{" "}
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              <bdt className="question">
                                                                                info@mosaictap.com
                                                                              </bdt>
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="else-block">
                                                                            <bdt className="block-component" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="privacyrights"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  9.
                                                                                  WHAT
                                                                                  ARE
                                                                                  YOUR
                                                                                  PRIVACY
                                                                                  RIGHTS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="block-component" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="block-component" />
                                                                            In{" "}
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            some
                                                                            regions,
                                                                            such
                                                                            as{" "}
                                                                              <bdt className="block-component" />
                                                                            the
                                                                            European
                                                                            Economic
                                                                            Area
                                                                            (EEA),
                                                                            United
                                                                            Kingdom
                                                                            (UK),
                                                                            and
                                                                            Switzerland
                                                                            <bdt className="block-component" />
                                                                            ,
                                                                            you
                                                                            have
                                                                            rights
                                                                            that
                                                                            allow
                                                                            you
                                                                            greater
                                                                            access
                                                                            to
                                                                            and
                                                                            control
                                                                            over
                                                                            your
                                                                            personal
                                                                            information.
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                                &nbsp;
                                                                            </span>
                                                                            You
                                                                            may
                                                                            review,
                                                                            change,
                                                                            or
                                                                            terminate
                                                                            your
                                                                            account
                                                                            at
                                                                            any
                                                                            time,
                                                                            depending
                                                                            on
                                                                            your
                                                                            country,
                                                                            province,
                                                                            or
                                                                            state
                                                                            of
                                                                            residence.
                                                                          </em>
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          In
                                                                          some
                                                                          regions
                                                                          (like{" "}
                                                                            <bdt className="block-component" />
                                                                          the
                                                                          EEA,
                                                                          UK,
                                                                          and
                                                                          Switzerland
                                                                          <bdt className="block-component" />
                                                                          ), you
                                                                          have
                                                                          certain
                                                                          rights
                                                                          under
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          These
                                                                          may
                                                                          include
                                                                          the
                                                                          right
                                                                          (i) to
                                                                          request
                                                                          access
                                                                          and
                                                                          obtain
                                                                          a copy
                                                                          of
                                                                          your
                                                                          personal
                                                                          information,
                                                                          (ii)
                                                                          to
                                                                          request
                                                                          rectification
                                                                          or
                                                                          erasure;
                                                                          (iii)
                                                                          to
                                                                          restrict
                                                                          the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information;
                                                                          (iv)
                                                                          if
                                                                          applicable,
                                                                          to
                                                                          data
                                                                          portability;
                                                                          and
                                                                          (v)
                                                                          not to
                                                                          be
                                                                          subject
                                                                          to
                                                                          automated
                                                                          decision-making.
                                                                          In
                                                                          certain
                                                                          circumstances,
                                                                          you
                                                                          may
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          object
                                                                          to the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information.
                                                                          You
                                                                          can
                                                                          make
                                                                          such a
                                                                          request
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                            <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#contact"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />{" "}
                                                                            below.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          consider
                                                                          and
                                                                          act
                                                                          upon
                                                                          any
                                                                          request
                                                                          in
                                                                          accordance
                                                                          with
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      &nbsp;
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in the
                                                                          EEA or
                                                                          UK and
                                                                          you
                                                                          believe
                                                                          we are
                                                                          unlawfully
                                                                          processing
                                                                          your
                                                                          personal
                                                                          information,
                                                                          you
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          complain
                                                                          to
                                                                          your{" "}
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(0, 58, 250)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <a
                                                                                        data-custom-class="link"
                                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              fontSize: 15
                                                                                          }}
                                                                                      >
                                                                                        Member
                                                                                        State
                                                                                        data
                                                                                        protection
                                                                                        authority
                                                                                      </span>
                                                                                    </a>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>{" "}
                                                                            or&nbsp;
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                                                                                                        rel="noopener noreferrer"
                                                                                                                                                        target="_blank"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            UK
                                                                            data
                                                                            protection
                                                                            authority
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in
                                                                          Switzerland,
                                                                          you
                                                                          may
                                                                          contact
                                                                          the{" "}
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(0, 58, 250)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                "rgb(0, 58, 250)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <a
                                                                                          data-custom-class="link"
                                                                                          href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                          rel="noopener noreferrer"
                                                                                          target="_blank"
                                                                                      >
                                                                                        Federal
                                                                                        Data
                                                                                        Protection
                                                                                        and
                                                                                        Information
                                                                                        Commissioner
                                                                                      </a>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="withdrawconsent"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <u>
                                                                              Withdrawing
                                                                              your
                                                                              consent:
                                                                            </u>
                                                                          </strong>{" "}
                                                                            If we
                                                                          are
                                                                          relying
                                                                          on
                                                                          your
                                                                          consent
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          <bdt className="block-component" />
                                                                          <bdt className="else-block" />{" "}
                                                                            you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time.
                                                                          You
                                                                          can
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                            <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#contact"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />{" "}
                                                                            below
                                                                          <bdt className="block-component" />
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        However,
                                                                        please
                                                                        note
                                                                        that
                                                                        this
                                                                        will not
                                                                        affect
                                                                        the
                                                                        lawfulness
                                                                        of the
                                                                        processing
                                                                        before
                                                                        its
                                                                        withdrawal
                                                                        nor,
                                                                        <bdt className="block-component" />
                                                                        <bdt className="else-block" />{" "}
                                                                          will it
                                                                        affect
                                                                        the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information
                                                                        conducted
                                                                        in
                                                                        reliance
                                                                        on
                                                                        lawful
                                                                        processing
                                                                        grounds
                                                                        other
                                                                        than
                                                                        consent.
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        If you
                                                                        have
                                                                        questions
                                                                        or
                                                                        comments
                                                                        about
                                                                        your
                                                                        privacy
                                                                        rights,
                                                                        you may
                                                                        email us
                                                                        at{" "}
                                                                          <bdt className="question">
                                                                          info@mosaictap.com
                                                                        </bdt>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="DNT"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  10.
                                                                                  CONTROLS
                                                                                  FOR
                                                                                  DO-NOT-TRACK
                                                                                  FEATURES
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Most
                                                                          web
                                                                          browsers
                                                                          and
                                                                          some
                                                                          mobile
                                                                          operating
                                                                          systems
                                                                          and
                                                                          mobile
                                                                          applications
                                                                          include
                                                                          a
                                                                          Do-Not-Track
                                                                          (
                                                                          <bdt className="block-component" />
                                                                          'DNT'
                                                                          <bdt className="else-block" />
                                                                          )
                                                                          feature
                                                                          or
                                                                          setting
                                                                          you
                                                                          can
                                                                          activate
                                                                          to
                                                                          signal
                                                                          your
                                                                          privacy
                                                                          preference
                                                                          not to
                                                                          have
                                                                          data
                                                                          about
                                                                          your
                                                                          online
                                                                          browsing
                                                                          activities
                                                                          monitored
                                                                          and
                                                                          collected.
                                                                          At
                                                                          this
                                                                          stage,
                                                                          no
                                                                          uniform
                                                                          technology
                                                                          standard
                                                                          for{" "}
                                                                            <bdt className="block-component" />
                                                                          recognising
                                                                          <bdt className="else-block" />{" "}
                                                                            and
                                                                          implementing
                                                                          DNT
                                                                          signals
                                                                          has
                                                                          been{" "}
                                                                            <bdt className="block-component" />
                                                                          finalised
                                                                          <bdt className="else-block" />
                                                                          . As
                                                                          such,
                                                                          we do
                                                                          not
                                                                          currently
                                                                          respond
                                                                          to DNT
                                                                          browser
                                                                          signals
                                                                          or any
                                                                          other
                                                                          mechanism
                                                                          that
                                                                          automatically
                                                                          communicates
                                                                          your
                                                                          choice
                                                                          not to
                                                                          be
                                                                          tracked
                                                                          online.
                                                                          If a
                                                                          standard
                                                                          for
                                                                          online
                                                                          tracking
                                                                          is
                                                                          adopted
                                                                          that
                                                                          we
                                                                          must
                                                                          follow
                                                                          in the
                                                                          future,
                                                                          we
                                                                          will
                                                                          inform
                                                                          you
                                                                          about
                                                                          that
                                                                          practice
                                                                          in a
                                                                          revised
                                                                          version
                                                                          of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="policyupdates"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  11.
                                                                                  DO
                                                                                  WE
                                                                                  MAKE
                                                                                  UPDATES
                                                                                  TO
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <strong>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </strong>
                                                                            Yes,
                                                                            we
                                                                            will
                                                                            update
                                                                            this
                                                                            notice
                                                                            as
                                                                            necessary
                                                                            to
                                                                            stay
                                                                            compliant
                                                                            with
                                                                            relevant
                                                                            laws.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          update
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          from
                                                                          time
                                                                          to
                                                                          time.
                                                                          The
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          indicated
                                                                          by an
                                                                          updated{" "}
                                                                            <bdt className="block-component" />
                                                                          'Revised'
                                                                          <bdt className="else-block" />{" "}
                                                                            date
                                                                          at the
                                                                          top of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                          If we
                                                                          make
                                                                          material
                                                                          changes
                                                                          to
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          we may
                                                                          notify
                                                                          you
                                                                          either
                                                                          by
                                                                          prominently
                                                                          posting
                                                                          a
                                                                          notice
                                                                          of
                                                                          such
                                                                          changes
                                                                          or by
                                                                          directly
                                                                          sending
                                                                          you a
                                                                          notification.
                                                                          We
                                                                          encourage
                                                                          you to
                                                                          review
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          frequently
                                                                          to be
                                                                          informed
                                                                          of how
                                                                          we are
                                                                          protecting
                                                                          your
                                                                          information.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="contact"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  12.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  CONTACT
                                                                                  US
                                                                                  ABOUT
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          have
                                                                          questions
                                                                          or
                                                                          comments
                                                                          about
                                                                          this
                                                                          notice,
                                                                          you
                                                                          may{" "}
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                              email
                                                                              us
                                                                              at{" "}
                                                                                <bdt className="question">
                                                                                info@mosaictap.com
                                                                                or&nbsp;
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                contact
                                                                                us
                                                                                by
                                                                                post
                                                                                at:
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="question">
                                                                                    Mosaic
                                                                                    Tap
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                          Arch
                                                                          7,
                                                                          Forth
                                                                          Goods
                                                                          Yard
                                                                        </bdt>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                          Newcastle
                                                                          upon
                                                                          Tyne
                                                                        </bdt>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                            ,{" "}
                                                                              <bdt className="question">
                                                                              Tyne
                                                                              and
                                                                              Wear
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="block-component" />{" "}
                                                                              <bdt className="question">
                                                                              NE1
                                                                              3PG
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <bdt className="question">
                                                                          United
                                                                          Kingdom
                                                                        </bdt>
                                                                        <bdt className="else-block" />
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <bdt className="question">
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component">
                                                                                      <bdt className="block-component" />
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                        />
                                                                      </bdt>
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                  <bdt className="block-component" />
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="request"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  13.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  REVIEW,
                                                                                  UPDATE,
                                                                                  OR
                                                                                  DELETE
                                                                                  THE
                                                                                  DATA
                                                                                  WE
                                                                                  COLLECT
                                                                                  FROM
                                                                                  YOU?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          Based
                                                                          on the
                                                                          applicable
                                                                          laws
                                                                          of
                                                                          your
                                                                          country
                                                                          <bdt className="block-component" />
                                                                          , you
                                                                          may
                                                                          <bdt className="else-block">
                                                                            <bdt className="block-component">
                                                                              {" "}
                                                                                have
                                                                              the
                                                                              right
                                                                              to
                                                                              request
                                                                              access
                                                                              to
                                                                              the
                                                                              personal
                                                                              information
                                                                              we
                                                                              collect
                                                                              from
                                                                              you,
                                                                              details
                                                                              about
                                                                              how
                                                                              we
                                                                              have
                                                                              processed
                                                                              it,
                                                                              correct
                                                                              inaccuracies,
                                                                              or
                                                                              delete
                                                                              your
                                                                              personal
                                                                              information.
                                                                              You
                                                                              may
                                                                              also
                                                                              have
                                                                              the
                                                                              right
                                                                              to
                                                                              withdraw
                                                                              your
                                                                              consent
                                                                              to
                                                                              our
                                                                              processing
                                                                              of
                                                                              your
                                                                              personal
                                                                              information.
                                                                              These
                                                                              rights
                                                                              may
                                                                              be
                                                                              limited
                                                                              in
                                                                              some
                                                                              circumstances
                                                                              by
                                                                              applicable
                                                                              law.
                                                                              To
                                                                              request
                                                                              to
                                                                              review,
                                                                              update,
                                                                              or
                                                                              delete
                                                                              your
                                                                              personal
                                                                              information,
                                                                              please{" "}
                                                                                <bdt className="block-component" />
                                                                              fill
                                                                              out
                                                                              and
                                                                              submit
                                                                              a&nbsp;
                                                                            </bdt>
                                                                          </bdt>
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <a
                                                                                  data-custom-class="link"
                                                                                  href="https://app.termly.io/notify/22e6bebd-8f21-4136-afd2-9b7e06fa5356"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                              >
                                                                                data
                                                                                subject
                                                                                access
                                                                                request
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="block-component">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      .
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <style
                                                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                                                        __html:
                                                                                                                                                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <>
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
                    }}
                />
                <span
                    style={{
                        display: "block",
                        margin: "0 auto 3.125rem",
                        width: "11.125rem",
                        height: "2.375rem",
                        background: "url(data:image/svg+xml"
                    }}
                />
                <div data-custom-class="body">
                    <div>
                        <strong>
        <span style={{ fontSize: 26 }}>
          <span data-custom-class="title">
            <bdt className="block-component" />
            <bdt className="question">PRIVACY POLICY</bdt>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
                        </strong>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">June 12, 2024</bdt>
            </span>
          </span>
        </strong>
      </span>
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This privacy notice for{" "}
              <bdt className="question">
              Mosaic Tap
              <bdt className="block-component" />
            </bdt>{" "}
              (<bdt className="block-component" />'<strong>we</strong>', '
            <strong>us</strong>', or '<strong>our</strong>'
            <bdt className="else-block" />
          </span>
          <span data-custom-class="body_text">
            ), describes how and why we might collect, store, use, and/or share
            (<bdt className="block-component" />'<strong>process</strong>'
            <bdt className="else-block" />) your information when you use our
            services (<bdt className="block-component" />'
            <strong>Services</strong>'<bdt className="else-block" />
            ), such as when you:
          </span>
        </span>
      </span>
                        <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Visit our website
              <bdt className="block-component" /> at{" "}
                <span style={{ color: "rgb(0, 58, 250)" }}>
                <bdt className="question">
                  <a
                      href="https://mosaictap.com"
                      target="_blank"
                      data-custom-class="link"
                  >
                    https://mosaictap.com
                  </a>
                </bdt>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          , or any website of ours that links to this privacy
                          notice
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </li>
                    </ul>
                    <div>
                        <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <ul>
                            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services.
              <bdt className="block-component" /> If you still have any
              questions or concerns, please contact us at{" "}
                <bdt className="question">info@mosaictap.com</bdt>.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
                            </strong>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our&nbsp;
              </em>
            </strong>
          </span>
        </span>
                            <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#personalinfo">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              personal information you disclose to us
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>{" "}
              <bdt className="block-component" />
            We do not process sensitive personal information.
            <bdt className="else-block" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we collect any information from third parties?</strong>{" "}
              <bdt className="block-component" />
            We do not collect any information from third parties.
            <bdt className="else-block" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we process your information
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
                <bdt className="block-component" />
              types of <bdt className="statement-end-if-in-editor" />
              parties do we share personal information?
            </strong>{" "}
              We may share information in specific situations and with specific{" "}
              <bdt className="block-component" />
            categories of <bdt className="statement-end-if-in-editor" />
            third parties. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#whoshare">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              when and with whom we share your personal information
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            .<bdt className="block-component" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have{" "}
              <bdt className="block-component" />
            organisational
            <bdt className="else-block" /> and technical processes and
            procedures in place to protect your personal information. However,
            no electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other{" "}
              <bdt className="block-component" />
            unauthorised
            <bdt className="else-block" /> third parties will not be able to
            defeat our security and improperly collect, access, steal, or modify
            your information. Learn more about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we keep your information safe
            </span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
                            </a>
                            <span data-custom-class="body_text">.</span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by{" "}
              <bdt className="block-component">submitting a&nbsp;</bdt>
          </span>
        </span>
                            <a
                                data-custom-class="link"
                                href="https://app.termly.io/notify/22e6bebd-8f21-4136-afd2-9b7e06fa5356"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              data subject access request
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />, or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
        </span>
                            <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Review the privacy notice in full
            </span>
          </span>
                            </a>
                            <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">.</span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
              <bdt className="block-component" />
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#legalbases">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              3.{" "}
                <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(0, 58, 250)" }}>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </span>
              </span>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#whoshare">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infominors">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <a data-custom-class="link" href="#privacyrights">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(0, 58, 250)" }}>
              10. CONTROLS FOR DO-NOT-TRACK FEATURES
              <bdt className="block-component" />
            </span>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </bdt>
          </a>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </bdt>
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#DNT">
                                <span style={{ fontSize: 15 }} />
                            </a>
                            <a data-custom-class="link" href="#policyupdates">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            11. DO WE MAKE UPDATES TO THIS NOTICE?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
                            </a>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div id="personalinfo" style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        We collect personal information that you provide to us.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
                <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
            <span data-custom-class="body_text">
              express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the
              Services, or otherwise when you contact us.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
                        </div>
                        <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>{" "}
              <bdt className="block-component" />
            We do not process sensitive information.
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="else-block" />
          </span>
        </span>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component" />
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>Information automatically collected</strong>
          </span>
        </span>
                        </div>
                        <div>
                            <div>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>&nbsp;</em>
                      </strong>
                      <em>
                        Some information — such as your Internet Protocol (IP)
                        address and/or browser and device characteristics — is
                        collected automatically when you visit our Services.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </span>
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The information we collect includes:
              <bdt className="block-component" />
            </span>
          </span>
        </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
                                </bdt>
                            </div>
                            <ul>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
                                </bdt>
                            </div>
                            <ul>
                                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </li>
                            </ul>
                            <div>
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                </bdt>
                                <bdt className="statement-end-if-in-editor" />
                                <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
                                <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <br />
                            </div>
                            <div id="infouse" style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
                            </div>
                            <div>
                                <div style={{ lineHeight: "1.5" }}>
                                    <br />
                                </div>
                                <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short:&nbsp;</em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
                                </div>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                                <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
                                <div style={{ lineHeight: "1.5" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
                                    <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
                                        <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                                            <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <bdt className="block-component">
                                                            <span style={{ fontSize: 15 }} />
                                                        </bdt>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }} />
                                                            </bdt>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                                                                </bdt>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </bdt>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }} />
                                                                    </bdt>
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component" />
                                                                </p>
                                                                <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                                    <bdt className="block-component" />
                                                                </p>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                                                                    </bdt>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }} />
                                                                        </bdt>
                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                            <bdt className="block-component">
                                                                                <span style={{ fontSize: 15 }} />
                                                                            </bdt>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <bdt className="block-component">
                                      <span data-custom-class="body_text" />
                                    </bdt>
                                  </span>
                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                                                                    </bdt>
                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                        <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                                                                        </bdt>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                                                                            </bdt>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                                                                                </bdt>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                                                                                    </bdt>
                                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                                        <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                                                                                        </bdt>
                                                                                                        <div
                                                                                                            style={{ lineHeight: "1.5" }}
                                                                                                        >
                                                                                                            <bdt className="block-component">
                                                  <span
                                                      style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                                                                            </bdt>
                                                                                                            <div
                                                                                                                style={{ lineHeight: "1.5" }}
                                                                                                            >
                                                                                                                <bdt className="block-component">
                                                    <span
                                                        style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                                                                                </bdt>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        lineHeight: "1.5"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component">
                                                      <span
                                                          style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                                                                                    </bdt>
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            lineHeight: "1.5"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component">
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                                                                                        </bdt>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li
                                                                                                                                data-custom-class="body_text"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <strong>
                                                                To save or
                                                                protect an
                                                                individual's
                                                                vital interest.
                                                              </strong>{" "}
                                                                We may process
                                                              your information
                                                              when necessary to
                                                              save or protect an
                                                              individual’s vital
                                                              interest, such as
                                                              to prevent harm.
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            id="legalbases"
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <strong>
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="heading_1">
                                                              3. WHAT LEGAL
                                                              BASES DO WE RELY
                                                              ON TO PROCESS YOUR
                                                              INFORMATION?
                                                            </span>
                                                          </span>
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <em>
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                In Short:&nbsp;
                                                              </strong>
                                                              We only process
                                                              your personal
                                                              information when
                                                              we believe it is
                                                              necessary and we
                                                              have a valid legal
                                                              reason (i.e.
                                                              <bdt className="block-component" />{" "}
                                                                legal basis) to do
                                                              so under
                                                              applicable law,
                                                              like with your
                                                              consent, to comply
                                                              with laws, to
                                                              provide you with
                                                              services to enter
                                                              into or{" "}
                                                                <bdt className="block-component" />
                                                              fulfil
                                                              <bdt className="else-block" />{" "}
                                                                our contractual
                                                              obligations, to
                                                              protect your
                                                              rights, or to{" "}
                                                                <bdt className="block-component" />
                                                              fulfil
                                                              <bdt className="else-block" />{" "}
                                                                our legitimate
                                                              business
                                                              interests.
                                                            </span>
                                                          </span>
                                                                                                                            </em>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <br />
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                        <span
                                                            style={{
                                                                fontSize: 15
                                                            }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            The General Data
                                                            Protection
                                                            Regulation (GDPR)
                                                            and UK GDPR require
                                                            us to explain the
                                                            valid legal bases we
                                                            rely on in order to
                                                            process your
                                                            personal
                                                            information. As
                                                            such, we may rely on
                                                            the following legal
                                                            bases to process
                                                            your personal
                                                            information:
                                                          </span>
                                                        </span>
                                                                                                                        </div>
                                                                                                                        <ul>
                                                                                                                            <li
                                                                                                                                data-custom-class="body_text"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Consent.&nbsp;
                                                              </strong>
                                                              We may process
                                                              your information
                                                              if you have given
                                                              us permission
                                                              (i.e.
                                                              <bdt className="block-component" />{" "}
                                                                consent) to use
                                                              your personal
                                                              information for a
                                                              specific purpose.
                                                              You can withdraw
                                                              your consent at
                                                              any time. Learn
                                                              more about&nbsp;
                                                            </span>
                                                          </span>
                                                                                                                                <a
                                                                                                                                    data-custom-class="link"
                                                                                                                                    href="#withdrawconsent"
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "rgb(0, 58, 250)",
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                withdrawing your
                                                                consent
                                                              </span>
                                                            </span>
                                                                                                                                </a>
                                                                                                                                <span data-custom-class="body_text">
                                                            .
                                                          </span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                        <div
                                                                                                                            style={{
                                                                                                                                lineHeight: "1.5"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="block-component">
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                                                                                            </bdt>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <strong>
                                                                  Legal
                                                                  Obligations.
                                                                </strong>{" "}
                                                                  We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                for compliance
                                                                with our legal
                                                                obligations,
                                                                such as to
                                                                cooperate with a
                                                                law enforcement
                                                                body or
                                                                regulatory
                                                                agency, exercise
                                                                or defend our
                                                                legal rights, or
                                                                disclose your
                                                                information as
                                                                evidence in
                                                                litigation in
                                                                which we are
                                                                involved.
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <br />
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <strong>
                                                                  Vital
                                                                  Interests.
                                                                </strong>{" "}
                                                                  We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                to protect your
                                                                vital interests
                                                                or the vital
                                                                interests of a
                                                                third party,
                                                                such as
                                                                situations
                                                                involving
                                                                potential
                                                                threats to the
                                                                safety of any
                                                                person.
                                                              </span>
                                                            </span>
                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                  style={{
                                                                      fontSize: 15
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                                                                                                    </bdt>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <bdt className="block-component">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                                <span data-custom-class="body_text">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <bdt className="block-component">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                                                                                                </bdt>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                id="whoshare"
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  color:
                                                                      "rgb(127, 127, 127)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    color:
                                                                        "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                            "rgb(89, 89, 89)"
                                                                    }}
                                                                >
                                                                  <span
                                                                      id="control"
                                                                      style={{
                                                                          color:
                                                                              "rgb(0, 0, 0)"
                                                                      }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        4. WHEN
                                                                        AND WITH
                                                                        WHOM DO
                                                                        WE SHARE
                                                                        YOUR
                                                                        PERSONAL
                                                                        INFORMATION?
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>
                                                                    In Short:
                                                                  </em>
                                                                </strong>
                                                                <em>
                                                                  &nbsp;We may
                                                                  share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in
                                                                  this section
                                                                  and/or with
                                                                  the following{" "}
                                                                    <bdt className="block-component" />
                                                                  categories of{" "}
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Vendors,
                                                                Consultants, and
                                                                Other
                                                                Third-Party
                                                                Service
                                                                Providers.
                                                              </strong>{" "}
                                                                We may share your
                                                              data with
                                                              third-party
                                                              vendors, service
                                                              providers,
                                                              contractors, or
                                                              agents (
                                                              <bdt className="block-component" />
                                                              '
                                                              <strong>
                                                                third parties
                                                              </strong>
                                                              '
                                                              <bdt className="else-block" />
                                                              ) who perform
                                                              services for us or
                                                              on our behalf and
                                                              require access to
                                                              such information
                                                              to do that work.{" "}
                                                                <bdt className="block-component" />
                                                              We have contracts
                                                              in place with our
                                                              third parties,
                                                              which are designed
                                                              to help safeguard
                                                              your personal
                                                              information. This
                                                              means that they
                                                              cannot do anything
                                                              with your personal
                                                              information unless
                                                              we have instructed
                                                              them to do it.
                                                              They will also not
                                                              share your
                                                              personal
                                                              information with
                                                              any{" "}
                                                                <bdt className="block-component" />
                                                              organisation
                                                              <bdt className="else-block" />{" "}
                                                                apart from us.
                                                              They also commit
                                                              to pr
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              otect the data
                                                              they hold on our
                                                              behalf and to
                                                              retain it for the
                                                              period we
                                                              instruct.
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <bdt className="block-component" />
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              The{" "}
                                                                <bdt className="block-component" />
                                                              categories of{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                              third parties we
                                                              may share personal
                                                              information with
                                                              are as follows:
                                                            </span>
                                                          </span>
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Data
                                                                    Analytics
                                                                    Services
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div>
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span
                                                                      style={{
                                                                          color:
                                                                              "rgb(89, 89, 89)"
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span
                                                                  style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                          "rgb(89, 89, 89)"
                                                                  }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Performance
                                                                    Monitoring
                                                                    Tools
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div>
                                                          <span
                                                              style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                      "rgb(89, 89, 89)"
                                                              }}
                                                          >
                                                            <span
                                                                style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                        "rgb(89, 89, 89)"
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span
                                                                      style={{
                                                                          color:
                                                                              "rgb(89, 89, 89)"
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="forloop-component">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                                "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                              style={{
                                                                                                  fontSize: 15
                                                                                              }}
                                                                                          >
                                                                                            <span
                                                                                                style={{
                                                                                                    color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15
                                                                                                    }}
                                                                                                >
                                                                                                  <span
                                                                                                      style={{
                                                                                                          color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                  >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15
                                                                                                        }}
                                                                                                    >
                                                                                                      <span
                                                                                                          style={{
                                                                                                              color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                      >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                          <span
                                                                                                              style={{
                                                                                                                  color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                          >
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <bdt className="block-component" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                                <span data-custom-class="body_text" />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <br />
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We{" "}
                                                                <bdt className="block-component" />
                                                              also{" "}
                                                                <bdt className="statement-end-if-in-editor" />
                                                              may need to share
                                                              your personal
                                                              information in the
                                                              following
                                                              situations:
                                                            </span>
                                                          </span>
                                                                                                                            </div>
                                                                                                                            <ul>
                                                                                                                                <li
                                                                                                                                    data-custom-class="body_text"
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Business
                                                                  Transfers.
                                                                </strong>{" "}
                                                                  We may share or
                                                                transfer your
                                                                information in
                                                                connection with,
                                                                or during
                                                                negotiations of,
                                                                any merger, sale
                                                                of company
                                                                assets,
                                                                financing, or
                                                                acquisition of
                                                                all or a portion
                                                                of our business
                                                                to another
                                                                company.
                                                              </span>
                                                            </span>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                            <div
                                                                                                                                style={{
                                                                                                                                    lineHeight: "1.5"
                                                                                                                                }}
                                                                                                                            >
                                                          <span
                                                              style={{
                                                                  fontSize: 15
                                                              }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                                                                                                <div
                                                                                                                                    style={{
                                                                                                                                        lineHeight: "1.5"
                                                                                                                                    }}
                                                                                                                                >
                                                            <span
                                                                style={{
                                                                    fontSize: 15
                                                                }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                                                                                                    <div
                                                                                                                                        style={{
                                                                                                                                            lineHeight:
                                                                                                                                                "1.5"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="block-component">
                                                                <span
                                                                    style={{
                                                                        fontSize: 15
                                                                    }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                                                                                                        </bdt>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                lineHeight:
                                                                                                                                                    "1.5"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <bdt className="block-component">
                                                                  <span
                                                                      style={{
                                                                          fontSize: 15
                                                                      }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                                                                                            </bdt>
                                                                                                                                            <div
                                                                                                                                                style={{
                                                                                                                                                    lineHeight:
                                                                                                                                                        "1.5"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <bdt className="block-component">
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                                                                                                </bdt>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <bdt className="block-component">
                                                                                <span data-custom-class="heading_1">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="cookies"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  5.
                                                                                  DO
                                                                                  WE
                                                                                  USE
                                                                                  COOKIES
                                                                                  AND
                                                                                  OTHER
                                                                                  TRACKING
                                                                                  TECHNOLOGIES?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            may
                                                                            use
                                                                            cookies
                                                                            and
                                                                            other
                                                                            tracking
                                                                            technologies
                                                                            to
                                                                            collect
                                                                            and
                                                                            store
                                                                            your
                                                                            information.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          use
                                                                          cookies
                                                                          and
                                                                          similar
                                                                          tracking
                                                                          technologies
                                                                          (like
                                                                          web
                                                                          beacons
                                                                          and
                                                                          pixels)
                                                                          to
                                                                          gather
                                                                          information
                                                                          when
                                                                          you
                                                                          interact
                                                                          with
                                                                          our
                                                                          Services.
                                                                          Some
                                                                          online
                                                                          tracking
                                                                          technologies
                                                                          help
                                                                          us
                                                                          maintain
                                                                          the
                                                                          security
                                                                          of our
                                                                          Services
                                                                          <bdt className="block-component" />
                                                                          ,
                                                                          prevent
                                                                          crashes,
                                                                          fix
                                                                          bugs,
                                                                          save
                                                                          your
                                                                          preferences,
                                                                          and
                                                                          assist
                                                                          with
                                                                          basic
                                                                          site
                                                                          functions.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          also
                                                                          permit
                                                                          third
                                                                          parties
                                                                          and
                                                                          service
                                                                          providers
                                                                          to use
                                                                          online
                                                                          tracking
                                                                          technologies
                                                                          on our
                                                                          Services
                                                                          for
                                                                          analytics
                                                                          and
                                                                          advertising,
                                                                          including
                                                                          to
                                                                          help
                                                                          manage
                                                                          and
                                                                          display
                                                                          advertisements,
                                                                          to
                                                                          tailor
                                                                          advertisements
                                                                          to
                                                                          your
                                                                          interests,
                                                                          or to
                                                                          send
                                                                          abandoned
                                                                          shopping
                                                                          cart
                                                                          reminders
                                                                          (depending
                                                                          on
                                                                          your
                                                                          communication
                                                                          preferences).
                                                                          The
                                                                          third
                                                                          parties
                                                                          and
                                                                          service
                                                                          providers
                                                                          use
                                                                          their
                                                                          technology
                                                                          to
                                                                          provide
                                                                          advertising
                                                                          about
                                                                          products
                                                                          and
                                                                          services
                                                                          tailored
                                                                          to
                                                                          your
                                                                          interests
                                                                          which
                                                                          may
                                                                          appear
                                                                          either
                                                                          on our
                                                                          Services
                                                                          or on
                                                                          other
                                                                          websites.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Specific
                                                                          information
                                                                          about
                                                                          how we
                                                                          use
                                                                          such
                                                                          technologies
                                                                          and
                                                                          how
                                                                          you
                                                                          can
                                                                          refuse
                                                                          certain
                                                                          cookies
                                                                          is set
                                                                          out in
                                                                          our
                                                                          Cookie
                                                                          Notice
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              .
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <br />
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_2">
                                                                          Google
                                                                          Analytics
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <br />
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        We may
                                                                        share
                                                                        your
                                                                        information
                                                                        with
                                                                        Google
                                                                        Analytics
                                                                        to track
                                                                        and{" "}
                                                                          <bdt className="block-component" />
                                                                        analyse
                                                                        <bdt className="else-block" />{" "}
                                                                          the use
                                                                        of the
                                                                        Services.
                                                                        <bdt className="block-component" />{" "}
                                                                          The
                                                                        Google
                                                                        Analytics
                                                                        Advertising
                                                                        Features
                                                                        that we
                                                                        may use
                                                                        include:{" "}
                                                                          <bdt className="forloop-component" />
                                                                        <bdt className="block-component" />
                                                                        <bdt className="question">
                                                                          Google
                                                                          Analytics
                                                                          Demographics
                                                                          and
                                                                          Interests
                                                                          Reporting
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="forloop-component" />
                                                                        .
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                          To opt
                                                                        out of
                                                                        being
                                                                        tracked
                                                                        by
                                                                        Google
                                                                        Analytics
                                                                        across
                                                                        the
                                                                        Services,
                                                                        visit{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-fr-linked="true"
                                                                              href="https://tools.google.com/dlpage/gaoptout"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            https://tools.google.com/dlpage/gaoptout
                                                                          </a>
                                                                          .
                                                                        </span>
                                                                        <bdt className="block-component" />{" "}
                                                                          You can
                                                                        opt out
                                                                        of
                                                                        Google
                                                                        Analytics
                                                                        Advertising
                                                                        Features
                                                                        through{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="https://adssettings.google.com/"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            Ads
                                                                            Settings
                                                                          </a>
                                                                        </span>{" "}
                                                                          and Ad
                                                                        Settings
                                                                        for
                                                                        mobile
                                                                        apps.
                                                                        Other
                                                                        opt out
                                                                        means
                                                                        include{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rbg(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                            <a
                                                                                data-custom-class="link"
                                                                                href="http://optout.networkadvertising.org/"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                            >
                                                                              http://optout.networkadvertising.org/
                                                                            </a>
                                                                          </span>
                                                                        </span>{" "}
                                                                          and{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="http://www.networkadvertising.org/mobile-choice"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            http://www.networkadvertising.org/mobile-choice
                                                                          </a>
                                                                        </span>
                                                                        .
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                          For more
                                                                        information
                                                                        on the
                                                                        privacy
                                                                        practices
                                                                        of
                                                                        Google,
                                                                        please
                                                                        visit
                                                                        the{" "}
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(0, 58, 250)"
                                                                              }}
                                                                          >
                                                                          <a
                                                                              data-custom-class="link"
                                                                              href="https://policies.google.com/privacy"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                          >
                                                                            Google
                                                                            Privacy
                                                                              &amp;
                                                                              Terms
                                                                            page
                                                                          </a>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize: 15
                                                                                      }}
                                                                                  >
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                  <span
                                                                                      style={{
                                                                                          color:
                                                                                              "rgb(89, 89, 89)"
                                                                                      }}
                                                                                  >
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                        <bdt className="block-component">
                                                                                          <span data-custom-class="body_text">
                                                                                            <bdt className="block-component" />
                                                                                          </span>
                                                                                        </bdt>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="inforetain"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  6.
                                                                                  HOW
                                                                                  LONG
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            keep
                                                                            your
                                                                            information
                                                                            for
                                                                            as
                                                                            long
                                                                            as
                                                                            necessary
                                                                            to{" "}
                                                                              <bdt className="block-component" />
                                                                            fulfil
                                                                            <bdt className="else-block" />{" "}
                                                                              the
                                                                            purposes
                                                                            outlined
                                                                            in
                                                                            this
                                                                            privacy
                                                                            notice
                                                                            unless
                                                                            otherwise
                                                                            required
                                                                            by
                                                                            law.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          only
                                                                          keep
                                                                          your
                                                                          personal
                                                                          information
                                                                          for as
                                                                          long
                                                                          as it
                                                                          is
                                                                          necessary
                                                                          for
                                                                          the
                                                                          purposes
                                                                          set
                                                                          out in
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          unless
                                                                          a
                                                                          longer
                                                                          retention
                                                                          period
                                                                          is
                                                                          required
                                                                          or
                                                                          permitted
                                                                          by law
                                                                          (such
                                                                          as
                                                                          tax,
                                                                          accounting,
                                                                          or
                                                                          other
                                                                          legal
                                                                          requirements).
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          When
                                                                          we
                                                                          have
                                                                          no
                                                                          ongoing
                                                                          legitimate
                                                                          business
                                                                          need
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          we
                                                                          will
                                                                          either
                                                                          delete
                                                                          or{" "}
                                                                            <bdt className="block-component" />
                                                                          anonymise
                                                                          <bdt className="else-block" />{" "}
                                                                            such
                                                                          information,
                                                                          or, if
                                                                          this
                                                                          is not
                                                                          possible
                                                                          (for
                                                                          example,
                                                                          because
                                                                          your
                                                                          personal
                                                                          information
                                                                          has
                                                                          been
                                                                          stored
                                                                          in
                                                                          backup
                                                                          archives),
                                                                          then
                                                                          we
                                                                          will
                                                                          securely
                                                                          store
                                                                          your
                                                                          personal
                                                                          information
                                                                          and
                                                                          isolate
                                                                          it
                                                                          from
                                                                          any
                                                                          further
                                                                          processing
                                                                          until
                                                                          deletion
                                                                          is
                                                                          possible.
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="infosafe"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  7.
                                                                                  HOW
                                                                                  DO
                                                                                  WE
                                                                                  KEEP
                                                                                  YOUR
                                                                                  INFORMATION
                                                                                  SAFE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            We
                                                                            aim
                                                                            to
                                                                            protect
                                                                            your
                                                                            personal
                                                                            information
                                                                            through
                                                                            a
                                                                            system
                                                                            of{" "}
                                                                              <bdt className="block-component" />
                                                                            organisational
                                                                            <bdt className="else-block" />{" "}
                                                                              and
                                                                            technical
                                                                            security
                                                                            measures.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          have
                                                                          implemented
                                                                          appropriate
                                                                          and
                                                                          reasonable
                                                                          technical
                                                                          and{" "}
                                                                            <bdt className="block-component" />
                                                                          organisational
                                                                          <bdt className="else-block" />{" "}
                                                                            security
                                                                          measures
                                                                          designed
                                                                          to
                                                                          protect
                                                                          the
                                                                          security
                                                                          of any
                                                                          personal
                                                                          information
                                                                          we
                                                                          process.
                                                                          However,
                                                                          despite
                                                                          our
                                                                          safeguards
                                                                          and
                                                                          efforts
                                                                          to
                                                                          secure
                                                                          your
                                                                          information,
                                                                          no
                                                                          electronic
                                                                          transmission
                                                                          over
                                                                          the
                                                                          Internet
                                                                          or
                                                                          information
                                                                          storage
                                                                          technology
                                                                          can be
                                                                          guaranteed
                                                                          to be
                                                                          100%
                                                                          secure,
                                                                          so we
                                                                          cannot
                                                                          promise
                                                                          or
                                                                          guarantee
                                                                          that
                                                                          hackers,
                                                                          cybercriminals,
                                                                          or
                                                                          other{" "}
                                                                            <bdt className="block-component" />
                                                                          unauthorised
                                                                          <bdt className="else-block" />{" "}
                                                                            third
                                                                          parties
                                                                          will
                                                                          not be
                                                                          able
                                                                          to
                                                                          defeat
                                                                          our
                                                                          security
                                                                          and
                                                                          improperly
                                                                          collect,
                                                                          access,
                                                                          steal,
                                                                          or
                                                                          modify
                                                                          your
                                                                          information.
                                                                          Although
                                                                          we
                                                                          will
                                                                          do our
                                                                          best
                                                                          to
                                                                          protect
                                                                          your
                                                                          personal
                                                                          information,
                                                                          transmission
                                                                          of
                                                                          personal
                                                                          information
                                                                          to and
                                                                          from
                                                                          our
                                                                          Services
                                                                          is at
                                                                          your
                                                                          own
                                                                          risk.
                                                                          You
                                                                          should
                                                                          only
                                                                          access
                                                                          the
                                                                          Services
                                                                          within
                                                                          a
                                                                          secure
                                                                          environment.
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                          </span>
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="infominors"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  8.
                                                                                  DO
                                                                                  WE
                                                                                  COLLECT
                                                                                  INFORMATION
                                                                                  FROM
                                                                                  MINORS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;We
                                                                            do
                                                                            not
                                                                            knowingly
                                                                            collect
                                                                            data
                                                                            from
                                                                            or
                                                                            market
                                                                            to{" "}
                                                                              <bdt className="block-component" />
                                                                            children
                                                                            under
                                                                            18
                                                                            years
                                                                            of
                                                                            age
                                                                            <bdt className="else-block" />
                                                                            .
                                                                          </em>
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We do
                                                                          not
                                                                          knowingly
                                                                          collect,
                                                                          solicit
                                                                          data
                                                                          from,
                                                                          or
                                                                          market
                                                                          to
                                                                          children
                                                                          under
                                                                          18
                                                                          years
                                                                          of
                                                                          age,
                                                                          nor do
                                                                          we
                                                                          knowingly
                                                                          sell
                                                                          such
                                                                          personal
                                                                          information.
                                                                          By
                                                                          using
                                                                          the
                                                                          Services,
                                                                          you
                                                                          represent
                                                                          that
                                                                          you
                                                                          are at
                                                                          least
                                                                          18 or
                                                                          that
                                                                          you
                                                                          are
                                                                          the
                                                                          parent
                                                                          or
                                                                          guardian
                                                                          of
                                                                          such a
                                                                          minor
                                                                          and
                                                                          consent
                                                                          to
                                                                          such
                                                                          minor
                                                                          dependent’s
                                                                          use of
                                                                          the
                                                                          Services.
                                                                          If we
                                                                          learn
                                                                          that
                                                                          personal
                                                                          information
                                                                          from
                                                                          users
                                                                          less
                                                                          than
                                                                          18
                                                                          years
                                                                          of age
                                                                          has
                                                                          been
                                                                          collected,
                                                                          we
                                                                          will
                                                                          deactivate
                                                                          the
                                                                          account
                                                                          and
                                                                          take
                                                                          reasonable
                                                                          measures
                                                                          to
                                                                          promptly
                                                                          delete
                                                                          such
                                                                          data
                                                                          from
                                                                          our
                                                                          records.
                                                                          If you
                                                                          become
                                                                          aware
                                                                          of any
                                                                          data
                                                                          we may
                                                                          have
                                                                          collected
                                                                          from
                                                                          children
                                                                          under
                                                                          age
                                                                          18,
                                                                          please
                                                                          contact
                                                                          us at{" "}
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              <bdt className="question">
                                                                                info@mosaictap.com
                                                                              </bdt>
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="else-block">
                                                                            <bdt className="block-component" />
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="privacyrights"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  9.
                                                                                  WHAT
                                                                                  ARE
                                                                                  YOUR
                                                                                  PRIVACY
                                                                                  RIGHTS?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <em>
                                                                              In
                                                                              Short:
                                                                            </em>
                                                                          </strong>
                                                                          <em>
                                                                            &nbsp;
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="block-component" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <bdt className="block-component" />
                                                                            In{" "}
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            some
                                                                            regions,
                                                                            such
                                                                            as{" "}
                                                                              <bdt className="block-component" />
                                                                            the
                                                                            European
                                                                            Economic
                                                                            Area
                                                                            (EEA),
                                                                            United
                                                                            Kingdom
                                                                            (UK),
                                                                            and
                                                                            Switzerland
                                                                            <bdt className="block-component" />
                                                                            ,
                                                                            you
                                                                            have
                                                                            rights
                                                                            that
                                                                            allow
                                                                            you
                                                                            greater
                                                                            access
                                                                            to
                                                                            and
                                                                            control
                                                                            over
                                                                            your
                                                                            personal
                                                                            information.
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                                &nbsp;
                                                                            </span>
                                                                            You
                                                                            may
                                                                            review,
                                                                            change,
                                                                            or
                                                                            terminate
                                                                            your
                                                                            account
                                                                            at
                                                                            any
                                                                            time,
                                                                            depending
                                                                            on
                                                                            your
                                                                            country,
                                                                            province,
                                                                            or
                                                                            state
                                                                            of
                                                                            residence.
                                                                          </em>
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          In
                                                                          some
                                                                          regions
                                                                          (like{" "}
                                                                            <bdt className="block-component" />
                                                                          the
                                                                          EEA,
                                                                          UK,
                                                                          and
                                                                          Switzerland
                                                                          <bdt className="block-component" />
                                                                          ), you
                                                                          have
                                                                          certain
                                                                          rights
                                                                          under
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          These
                                                                          may
                                                                          include
                                                                          the
                                                                          right
                                                                          (i) to
                                                                          request
                                                                          access
                                                                          and
                                                                          obtain
                                                                          a copy
                                                                          of
                                                                          your
                                                                          personal
                                                                          information,
                                                                          (ii)
                                                                          to
                                                                          request
                                                                          rectification
                                                                          or
                                                                          erasure;
                                                                          (iii)
                                                                          to
                                                                          restrict
                                                                          the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information;
                                                                          (iv)
                                                                          if
                                                                          applicable,
                                                                          to
                                                                          data
                                                                          portability;
                                                                          and
                                                                          (v)
                                                                          not to
                                                                          be
                                                                          subject
                                                                          to
                                                                          automated
                                                                          decision-making.
                                                                          In
                                                                          certain
                                                                          circumstances,
                                                                          you
                                                                          may
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          object
                                                                          to the
                                                                          processing
                                                                          of
                                                                          your
                                                                          personal
                                                                          information.
                                                                          You
                                                                          can
                                                                          make
                                                                          such a
                                                                          request
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                            <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#contact"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />{" "}
                                                                            below.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We
                                                                          will
                                                                          consider
                                                                          and
                                                                          act
                                                                          upon
                                                                          any
                                                                          request
                                                                          in
                                                                          accordance
                                                                          with
                                                                          applicable
                                                                          data
                                                                          protection
                                                                          laws.
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      &nbsp;
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in the
                                                                          EEA or
                                                                          UK and
                                                                          you
                                                                          believe
                                                                          we are
                                                                          unlawfully
                                                                          processing
                                                                          your
                                                                          personal
                                                                          information,
                                                                          you
                                                                          also
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          complain
                                                                          to
                                                                          your{" "}
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(0, 58, 250)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <a
                                                                                        data-custom-class="link"
                                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              fontSize: 15
                                                                                          }}
                                                                                      >
                                                                                        Member
                                                                                        State
                                                                                        data
                                                                                        protection
                                                                                        authority
                                                                                      </span>
                                                                                    </a>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>{" "}
                                                                            or&nbsp;
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                                                                                                        rel="noopener noreferrer"
                                                                                                                                                        target="_blank"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            UK
                                                                            data
                                                                            protection
                                                                            authority
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          are
                                                                          located
                                                                          in
                                                                          Switzerland,
                                                                          you
                                                                          may
                                                                          contact
                                                                          the{" "}
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(0, 58, 250)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                "rgb(0, 58, 250)",
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <a
                                                                                          data-custom-class="link"
                                                                                          href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                          rel="noopener noreferrer"
                                                                                          target="_blank"
                                                                                      >
                                                                                        Federal
                                                                                        Data
                                                                                        Protection
                                                                                        and
                                                                                        Information
                                                                                        Commissioner
                                                                                      </a>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="withdrawconsent"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <strong>
                                                                            <u>
                                                                              Withdrawing
                                                                              your
                                                                              consent:
                                                                            </u>
                                                                          </strong>{" "}
                                                                            If we
                                                                          are
                                                                          relying
                                                                          on
                                                                          your
                                                                          consent
                                                                          to
                                                                          process
                                                                          your
                                                                          personal
                                                                          information,
                                                                          <bdt className="block-component" />
                                                                          <bdt className="else-block" />{" "}
                                                                            you
                                                                          have
                                                                          the
                                                                          right
                                                                          to
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time.
                                                                          You
                                                                          can
                                                                          withdraw
                                                                          your
                                                                          consent
                                                                          at any
                                                                          time
                                                                          by
                                                                          contacting
                                                                          us by
                                                                          using
                                                                          the
                                                                          contact
                                                                          details
                                                                          provided
                                                                          in the
                                                                          section{" "}
                                                                            <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <a
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        href="#contact"
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(0, 58, 250)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            HOW
                                                                            CAN
                                                                            YOU
                                                                            CONTACT
                                                                            US
                                                                            ABOUT
                                                                            THIS
                                                                            NOTICE?
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                                                                                                    </a>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          '
                                                                          <bdt className="else-block" />{" "}
                                                                            below
                                                                          <bdt className="block-component" />
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        However,
                                                                        please
                                                                        note
                                                                        that
                                                                        this
                                                                        will not
                                                                        affect
                                                                        the
                                                                        lawfulness
                                                                        of the
                                                                        processing
                                                                        before
                                                                        its
                                                                        withdrawal
                                                                        nor,
                                                                        <bdt className="block-component" />
                                                                        <bdt className="else-block" />{" "}
                                                                          will it
                                                                        affect
                                                                        the
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information
                                                                        conducted
                                                                        in
                                                                        reliance
                                                                        on
                                                                        lawful
                                                                        processing
                                                                        grounds
                                                                        other
                                                                        than
                                                                        consent.
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: 15
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            "rgb(89, 89, 89)"
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15
                                                                                        }}
                                                                                    >
                                                                                      <span
                                                                                          style={{
                                                                                              color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                          }}
                                                                                      >
                                                                                        <bdt className="block-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        If you
                                                                        have
                                                                        questions
                                                                        or
                                                                        comments
                                                                        about
                                                                        your
                                                                        privacy
                                                                        rights,
                                                                        you may
                                                                        email us
                                                                        at{" "}
                                                                          <bdt className="question">
                                                                          info@mosaictap.com
                                                                        </bdt>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text" />
                                                                      </span>
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="DNT"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  10.
                                                                                  CONTROLS
                                                                                  FOR
                                                                                  DO-NOT-TRACK
                                                                                  FEATURES
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Most
                                                                          web
                                                                          browsers
                                                                          and
                                                                          some
                                                                          mobile
                                                                          operating
                                                                          systems
                                                                          and
                                                                          mobile
                                                                          applications
                                                                          include
                                                                          a
                                                                          Do-Not-Track
                                                                          (
                                                                          <bdt className="block-component" />
                                                                          'DNT'
                                                                          <bdt className="else-block" />
                                                                          )
                                                                          feature
                                                                          or
                                                                          setting
                                                                          you
                                                                          can
                                                                          activate
                                                                          to
                                                                          signal
                                                                          your
                                                                          privacy
                                                                          preference
                                                                          not to
                                                                          have
                                                                          data
                                                                          about
                                                                          your
                                                                          online
                                                                          browsing
                                                                          activities
                                                                          monitored
                                                                          and
                                                                          collected.
                                                                          At
                                                                          this
                                                                          stage,
                                                                          no
                                                                          uniform
                                                                          technology
                                                                          standard
                                                                          for{" "}
                                                                            <bdt className="block-component" />
                                                                          recognising
                                                                          <bdt className="else-block" />{" "}
                                                                            and
                                                                          implementing
                                                                          DNT
                                                                          signals
                                                                          has
                                                                          been{" "}
                                                                            <bdt className="block-component" />
                                                                          finalised
                                                                          <bdt className="else-block" />
                                                                          . As
                                                                          such,
                                                                          we do
                                                                          not
                                                                          currently
                                                                          respond
                                                                          to DNT
                                                                          browser
                                                                          signals
                                                                          or any
                                                                          other
                                                                          mechanism
                                                                          that
                                                                          automatically
                                                                          communicates
                                                                          your
                                                                          choice
                                                                          not to
                                                                          be
                                                                          tracked
                                                                          online.
                                                                          If a
                                                                          standard
                                                                          for
                                                                          online
                                                                          tracking
                                                                          is
                                                                          adopted
                                                                          that
                                                                          we
                                                                          must
                                                                          follow
                                                                          in the
                                                                          future,
                                                                          we
                                                                          will
                                                                          inform
                                                                          you
                                                                          about
                                                                          that
                                                                          practice
                                                                          in a
                                                                          revised
                                                                          version
                                                                          of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                    <bdt className="block-component">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      />
                                                                                                                                                    </bdt>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="policyupdates"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  11.
                                                                                  DO
                                                                                  WE
                                                                                  MAKE
                                                                                  UPDATES
                                                                                  TO
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <strong>
                                                                              In
                                                                              Short:&nbsp;
                                                                            </strong>
                                                                            Yes,
                                                                            we
                                                                            will
                                                                            update
                                                                            this
                                                                            notice
                                                                            as
                                                                            necessary
                                                                            to
                                                                            stay
                                                                            compliant
                                                                            with
                                                                            relevant
                                                                            laws.
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          We may
                                                                          update
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          from
                                                                          time
                                                                          to
                                                                          time.
                                                                          The
                                                                          updated
                                                                          version
                                                                          will
                                                                          be
                                                                          indicated
                                                                          by an
                                                                          updated{" "}
                                                                            <bdt className="block-component" />
                                                                          'Revised'
                                                                          <bdt className="else-block" />{" "}
                                                                            date
                                                                          at the
                                                                          top of
                                                                          this
                                                                          privacy
                                                                          notice.
                                                                          If we
                                                                          make
                                                                          material
                                                                          changes
                                                                          to
                                                                          this
                                                                          privacy
                                                                          notice,
                                                                          we may
                                                                          notify
                                                                          you
                                                                          either
                                                                          by
                                                                          prominently
                                                                          posting
                                                                          a
                                                                          notice
                                                                          of
                                                                          such
                                                                          changes
                                                                          or by
                                                                          directly
                                                                          sending
                                                                          you a
                                                                          notification.
                                                                          We
                                                                          encourage
                                                                          you to
                                                                          review
                                                                          this
                                                                          privacy
                                                                          notice
                                                                          frequently
                                                                          to be
                                                                          informed
                                                                          of how
                                                                          we are
                                                                          protecting
                                                                          your
                                                                          information.
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="contact"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  12.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  CONTACT
                                                                                  US
                                                                                  ABOUT
                                                                                  THIS
                                                                                  NOTICE?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          If you
                                                                          have
                                                                          questions
                                                                          or
                                                                          comments
                                                                          about
                                                                          this
                                                                          notice,
                                                                          you
                                                                          may{" "}
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                              email
                                                                              us
                                                                              at{" "}
                                                                                <bdt className="question">
                                                                                info@mosaictap.com
                                                                                or&nbsp;
                                                                              </bdt>
                                                                              <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="block-component" />
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                contact
                                                                                us
                                                                                by
                                                                                post
                                                                                at:
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="question">
                                                                                    Mosaic
                                                                                    Tap
                                                                                  </bdt>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                          Arch
                                                                          7,
                                                                          Forth
                                                                          Goods
                                                                          Yard
                                                                        </bdt>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="question">
                                                                          Newcastle
                                                                          upon
                                                                          Tyne
                                                                        </bdt>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                            ,{" "}
                                                                              <bdt className="question">
                                                                              Tyne
                                                                              and
                                                                              Wear
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="block-component" />{" "}
                                                                              <bdt className="question">
                                                                              NE1
                                                                              3PG
                                                                            </bdt>
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                            <bdt className="block-component" />
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <bdt className="question">
                                                                          United
                                                                          Kingdom
                                                                        </bdt>
                                                                        <bdt className="else-block" />
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span
                                                                                                                                                        data-custom-class="body_text"
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                      <bdt className="question">
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor">
                                                                              <span
                                                                                  style={{
                                                                                      color:
                                                                                          "rgb(89, 89, 89)"
                                                                                  }}
                                                                              >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 15
                                                                                    }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component">
                                                                                      <bdt className="block-component" />
                                                                                    </bdt>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15
                                                                            }}
                                                                        />
                                                                      </bdt>
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span
                                                                              style={{
                                                                                  color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  fontSize: 15
                                                                              }}
                                                                          >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="statement-end-if-in-editor">
                                                                                  <bdt className="block-component" />
                                                                                </bdt>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    id="request"
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "rgb(127, 127, 127)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              color:
                                                                                  "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                          }}
                                                                      >
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                    "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                          <span
                                                                              style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                      "rgb(89, 89, 89)"
                                                                              }}
                                                                          >
                                                                            <span
                                                                                id="control"
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 0, 0)"
                                                                                }}
                                                                            >
                                                                              <strong>
                                                                                <span data-custom-class="heading_1">
                                                                                  13.
                                                                                  HOW
                                                                                  CAN
                                                                                  YOU
                                                                                  REVIEW,
                                                                                  UPDATE,
                                                                                  OR
                                                                                  DELETE
                                                                                  THE
                                                                                  DATA
                                                                                  WE
                                                                                  COLLECT
                                                                                  FROM
                                                                                  YOU?
                                                                                </span>
                                                                              </strong>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <br />
                                                                                                                                                </div>
                                                                                                                                                <div
                                                                                                                                                    style={{
                                                                                                                                                        lineHeight:
                                                                                                                                                            "1.5"
                                                                                                                                                    }}
                                                                                                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                                "rgb(89, 89, 89)"
                                                                        }}
                                                                    >
                                                                      <span
                                                                          style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                  "rgb(89, 89, 89)"
                                                                          }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          Based
                                                                          on the
                                                                          applicable
                                                                          laws
                                                                          of
                                                                          your
                                                                          country
                                                                          <bdt className="block-component" />
                                                                          , you
                                                                          may
                                                                          <bdt className="else-block">
                                                                            <bdt className="block-component">
                                                                              {" "}
                                                                                have
                                                                              the
                                                                              right
                                                                              to
                                                                              request
                                                                              access
                                                                              to
                                                                              the
                                                                              personal
                                                                              information
                                                                              we
                                                                              collect
                                                                              from
                                                                              you,
                                                                              details
                                                                              about
                                                                              how
                                                                              we
                                                                              have
                                                                              processed
                                                                              it,
                                                                              correct
                                                                              inaccuracies,
                                                                              or
                                                                              delete
                                                                              your
                                                                              personal
                                                                              information.
                                                                              You
                                                                              may
                                                                              also
                                                                              have
                                                                              the
                                                                              right
                                                                              to
                                                                              withdraw
                                                                              your
                                                                              consent
                                                                              to
                                                                              our
                                                                              processing
                                                                              of
                                                                              your
                                                                              personal
                                                                              information.
                                                                              These
                                                                              rights
                                                                              may
                                                                              be
                                                                              limited
                                                                              in
                                                                              some
                                                                              circumstances
                                                                              by
                                                                              applicable
                                                                              law.
                                                                              To
                                                                              request
                                                                              to
                                                                              review,
                                                                              update,
                                                                              or
                                                                              delete
                                                                              your
                                                                              personal
                                                                              information,
                                                                              please{" "}
                                                                                <bdt className="block-component" />
                                                                              fill
                                                                              out
                                                                              and
                                                                              submit
                                                                              a&nbsp;
                                                                            </bdt>
                                                                          </bdt>
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    "rgb(0, 58, 250)"
                                                                            }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                                style={{
                                                                                    color:
                                                                                        "rgb(0, 58, 250)",
                                                                                    fontSize: 15
                                                                                }}
                                                                            >
                                                                              <a
                                                                                  data-custom-class="link"
                                                                                  href="https://app.termly.io/notify/22e6bebd-8f21-4136-afd2-9b7e06fa5356"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                              >
                                                                                data
                                                                                subject
                                                                                access
                                                                                request
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="block-component">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                                                                                                    <span data-custom-class="body_text">
                                                                      .
                                                                    </span>
                                                                                                                                                </div>
                                                                                                                                                <style
                                                                                                                                                    dangerouslySetInnerHTML={{
                                                                                                                                                        __html:
                                                                                                                                                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                                                                                                                                    }}
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}

export default Privacy;