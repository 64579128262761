class TableLayout {
  constructor (date, assignedTables, emptyTables, emptySpaces, foldingChairs, totalPeople) {
    this.date = date;
    this.assignedTables = assignedTables;
    this.emptyTables = emptyTables;
    this.emptySpaces = emptySpaces;
    this.foldingChairs = foldingChairs;
    this.totalPeople = totalPeople;
  }
}

const tableLayoutConverter = {
  toFirestore: (layout) => {
    return {
      date: layout.date,
      assignedTables: layout.assignedTables,
      emptyTables: layout.emptyTables,
      emptySpaces: layout.emptySpaces,
      foldingChairs: layout.foldingChairs,
      totalPeople: layout.totalPeople
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new TableLayout(data.date, data.assignedTables, data.emptyTables, data.emptySpaces, data.foldingChairs, data.totalPeople);
  }
}
export {TableLayout, tableLayoutConverter}