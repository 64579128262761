import './beerLogoTile.css';
import './beerLogoTileMobile.css';

import {useRef} from "react";

const BeerLogoTile = (props) => {
    let setBeerCard = props.setBeerCard;

  let beer = props.beer;
  let imgRef = useRef(null);

  return (
    <div className={'grid-item'}>
      <img ref={imgRef} className={'beerLogoImg'} onClick={() => {
          setBeerCard(beer);
      }} src={beer.imageUrl} alt={beer.name !== '' ? beer.name + " logo" : ''}/>
    </div>
  )
}

export default BeerLogoTile;