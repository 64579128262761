// Stylesheets
import './quizDialog.css';
import './quizDialogMobile.css';
import './quizDialogTablet.css'

// React
import {useEffect, useState} from "react";

// Firebase
import {collection, addDoc, updateDoc, doc} from "firebase/firestore";
import {db} from "../../firebase";

// Objects
import {bookingConverter} from "../../objects";
import {EmailConfirmation, EmailUpdate} from "./emailLayouts";
import {BookingConfirm, BookingInfo, BookingSuccess} from "./";
import {renderToString} from "react-dom/server";

const QuizDialog = (props) => {
  let {open, setOpen, existingBookingInfo, update, bookingId, width} = props;
  let [bookingInfo, setBookingInfo] = useState(null)
  let [translates, setTranslates] = useState(width >= 1400 ? ['0svw', '50svw', '100svw'] : width < 1400 && width >= 640 ? ['0svw', '75svw', '150svw'] : ['0svw', '90svw', '180svw'])
  let [pageNo, setPageNo] = useState(1)
  let [loading, setLoading] = useState(false)

  const movePage = (pageNo) => {
    let newTranslation = [];
    if (width >= 1400) {
      if (pageNo === 1) {
        newTranslation = ['0svw', '50svw', '100svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-50svw', '0svw', '50svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-100svw', '-50svw', '0svw'];
      }
    } else if (width < 1400 && width >= 640) {
      if (pageNo === 1) {
        newTranslation = ['0svw', '75svw', '150svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-75svw', '0svw', '75svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-150svw', '-75svw', '0svw'];
      }
    } else {
      if (pageNo === 1) {
        newTranslation = ['0svw', '90svw', '180svw'];
      } else if (pageNo === 2) {
        newTranslation = ['-90svw', '0svw', '90svw'];
      } else if (pageNo === 3) {
        newTranslation = ['-180svw', '-90svw', '0svw'];
      }
    }

    setTranslates(newTranslation);
  }

  useEffect(() => {
    if (existingBookingInfo) {
      setBookingInfo(existingBookingInfo)
      movePage(1);
    } else {
      setBookingInfo(null)
    }
  }, [existingBookingInfo]);

  const sendBooking = async () => {
    setLoading(true)
    if (bookingInfo !== null) {
      if (bookingId === undefined && !update) {
        const ref = collection(db, "bookings").withConverter(bookingConverter);
        await addDoc(ref, bookingInfo).then(async (docRef) => {
          const mailRef = collection(db, "mail");

          let message = {
            to: bookingInfo.email,
            bcc: "isaac@mosaictap.com",
            message: {
              subject: `Mosaic Tap Quiz Booking Confirmation for ${bookingInfo.name} on ${bookingInfo.date.split(' [SOLD OUT]')[0]}`,
              html: renderToString(<EmailConfirmation bookingId={docRef.id} bookingInfo={bookingInfo}/>)
            }
          }

          await addDoc(mailRef, message).then(() => {
            setLoading(false)
            movePage(3)
          })
        })
      } else {
        const ref = doc(db, "bookings", bookingId).withConverter(bookingConverter)  // E.g. "bookings/Name-Sep18" `${name}-${date.split(' ')[1]}${date.split(' ')[2]}}`
        await updateDoc(ref, {
          name: bookingInfo.name,
          email: bookingInfo.email,
          size: bookingInfo.size,
          date: bookingInfo.date.split(' [SOLD OUT]')[0],
          time: bookingInfo.time
        }).then(async (docRef) => {
          const mailRef = collection(db, "mail");

          let message = {
            to: bookingInfo.email,
            bcc: "isaac@mosaictap.com",
            message: {
              subject: `Mosaic Tap Quiz Booking Updated for ${bookingInfo.name} on ${bookingInfo.date.split(' [SOLD OUT]')[0]}.`,
              html: renderToString(<EmailUpdate bookingId={bookingId} bookingInfo={bookingInfo}/>)
            }
          }

          await addDoc(mailRef, message).then(() => {
            setLoading(false)
            movePage(3);
          })
        })
      }
    } else {
      setLoading(false)
      alert("Something went wrong, try refreshing the page and try again.")
    }
  }

  return (
    <div className={'quizDialogContainer'}
         style={{opacity: open ? '100%' : '0%', pointerEvents: open ? 'visiblePainted' : 'none'}} onClick={() => {
      setOpen(false)
    }}>
      <div className={'quizDialogInnerContainer'} style={{marginTop: open ? '0px' : '110vh'}} onClick={(e) => {
        e.stopPropagation();
      }}>
        <BookingInfo translate={translates[0]} movePage={movePage} bookingInfo={bookingInfo}
                     setBookingInfo={setBookingInfo} width={width} update={update} bookingId={bookingId}/>
        <BookingConfirm translate={translates[1]} movePage={movePage} bookingInfo={bookingInfo}
                        sendBooking={sendBooking} width={width} loading={loading}/>
        <BookingSuccess translate={translates[2]} setOpen={setOpen}
                        bookingInfo={bookingInfo}/>
      </div>
    </div>
  )
}

export default QuizDialog;