import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase";
import {quizConverter} from "../objects";

const getNext10Wednesdays = () => {
  const wednesdays = [];
  const excludedDates = ["Wed Dec 25 2024"]
  const today = new Date(Date.now() - 86400000);
  let day = today.getDay();
  let diff = (3 + 7 - day) % 7; // 3 represents Wednesday (0 is Sunday)

  if (diff === 0) {
    diff = 7; // If today is Wednesday, start from next Wednesday
  }

  let nextWednesday = new Date(today);
  nextWednesday.setDate(today.getDate() + diff);

  let total = 10;
  for (let i = 0; i < total; i++) {
    let dateString = new Date(nextWednesday).toDateString()
    if (!excludedDates.includes(dateString)) {
      wednesdays.push(dateString);
    }
    nextWednesday.setDate(nextWednesday.getDate() + 7);
  }

  return wednesdays;
};

export const getNext10WednesdaysWithSoldOut = async () => {
  let wednesdays = getNext10Wednesdays();

  let currentlySoldOut = []
  const querySnapshot = await getDocs(collection(db, "quiz"));
  querySnapshot.forEach((doc) => {
    currentlySoldOut.push(quizConverter.fromFirestore(doc));
  });
  if (currentlySoldOut.length > 0 && wednesdays.length > 0) {
    for (let i = 0; i < wednesdays.length; i++) {
      for (let j = 0; j < currentlySoldOut.length; j++) {
        if (currentlySoldOut[j].date === wednesdays[i] && currentlySoldOut[j].soldOut) {
          wednesdays[i] = wednesdays[i] + " [SOLD OUT]"
        }
      }
    }
    return wednesdays;
  }
}

