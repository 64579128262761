class Quiz {
  constructor (date, soldOut) {
    this.date = date;
    this.soldOut = soldOut;
  }
}

const quizConverter = {
  toFirestore: (quiz) => {
    return {
      date: quiz.date,
      soldOut: quiz.soldOut
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Quiz(data.date, data.soldOut);
  }
}
export {Quiz, quizConverter}